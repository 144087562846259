import axios from '../api/axios';
import React, { useState,useEffect,useContext } from 'react';
const uuid = require('uuid');

let clientIp = '0.0.0.0'; // Replace this with the actual client IP address
let country_code = '';
let country_name = '';
let city = '';
let postal = '';
let latitude = '';
let longitude = '';
let state =  '';

const config = {
  headers: {
    'X-Forwarded-For': clientIp,
  },
};

// Store the credits in the database with the token as the key
export const storeActivity = async (sessionId ,event_type,current_page,object_type,specific_object) => {
const geoapi = process.env.REACT_APP_GEOLOCATION_DB_API_TOKEN;


    try{
      /* //axios.get('https://api.ipify.org?format=json')
      axios.get('https://geolocation-db.com/json/'+geoapi)
      .then((response) => {
        clientIp = response.data.IPv4;
        country_code = response.data.country_code;
        country_name = response.data.country_name;
        city = response.data.city;
        postal = response.data.postal;
        latitude = response.data.latitude;
        longitude = response.data.longitude;
        state = response.data.state;
        //////console.log(response.data);
        //////console.log(clientIp);
        //////console.log(country_code);
        //////console.log(country_name);
        //////console.log(city);
        //////console.log(postal);
        //////console.log(latitude);
        //////console.log(longitude);
        //////console.log(state); */

        ////console.log(process.env.REACT_APP_GEOLOCATION_DB_API_TOKEN);

        //axios.get('https://api.ipify.org?format=json')
      axios.get('https://api.ipgeolocation.io/ipgeo?apiKey='+geoapi)
      .then((response) => {
        clientIp = response.data.ip;
        country_code = response.data.country_code2;
        country_name = response.data.country_name;
        city = response.data.city;
        postal = response.data.zipcode;
        latitude = response.data.latitude;
        longitude = response.data.longitude;
        state = response.data.state_prov;
        //////console.log(response.data);
        //////console.log(clientIp);
        //////console.log(country_code);
        //////console.log(country_name);
        //////console.log(city);
        //////console.log(postal);
        //////console.log(latitude);
        //////console.log(longitude);
        //////console.log(state);
  try{


      axios.post('/log-activity', {  sessionId: sessionId,event_type:event_type,current_page:current_page,object_type:object_type,specific_object:specific_object,ip:clientIp,}, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      });


} catch (error) {
  console.error(error);  
  try{
       
  
    axios.post('/log-activity', {  sessionId: sessionId,ip:clientIp,event_type:event_type,current_page:current_page,object_type:object_type,specific_object:specific_object}, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true // This sends the cookie with the request
    });

    } catch (error) {
      console.error(error);
      // Store the token in local storage
    }
}
      })
      .catch((error) => {
        console.error(error);
      });
    }  catch (error) {
      console.error(error);
      try{
       
  
      axios.post('/log-activity', {  sessionId: sessionId,ip:clientIp,event_type:event_type,current_page:current_page,object_type:object_type,specific_object:specific_object}, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      });

      } catch (error) {
        console.error(error);
        // Store the token in local storage
      }
    }

};

export default storeActivity;

