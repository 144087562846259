import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "./api/axios";
import "./ResumeCritiquePage.css";

import ProgressBar from "./components/ProgressBar.jsx";
import AuthContext from "./context/AuthProvider";

// Speedometer 
import CircularProgress from "./components/ResumeCritique/CircularProgress";

// Factor card with color-coding
import FactorCard from "./components/ResumeCritique/FactorCard";



// Additional components
import FeaturedEmployers from "./FeaturedEmployers";
import SocialProof from "./SocialProof";

const OPENAI_API_KEY = process.env.REACT_APP_GPT_API_KEY;
const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_API_KEY;

const stripe = require('stripe')(STRIPE_SECRET_KEY);

function ResumeCritiquePage() {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  // Data states
  const [jobRequirements, setJobRequirements] = useState("");
  const [resumeText, setResumeText] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [critiqueFactors, setCritiqueFactors] = useState([]);
  const [displayedFactors, setDisplayedFactors] = useState([]);
  const [salaryDataMin, setSalaryDataMin] = useState("N/A");
  const [salaryDataMax, setSalaryDataMax] = useState("N/A");
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");

  // Animation states
  const [currentFactorIndex, setCurrentFactorIndex] = useState(0);
  const [isAnimatingFactor, setIsAnimatingFactor] = useState(false);
  const [currentScore, setCurrentScore] = useState(0);

  // UI states
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fadeOut, setFadeOut] = useState(false);

  // Sales pitch modal
  const [showSalesPitchModal, setShowSalesPitchModal] = useState(false);
  const [averageScore, setAverageScore] = useState(0);
  const [missingElements, setMissingElements] = useState("Not specified");



  // For unmount tracking
  const isMountedRef = useRef(true);

  useEffect(() => {
    if (auth?.sessionId) {
      axios.post('/log-activity', {
        sessionId: auth.sessionId,
        event_type: "Page Landing",
        current_page: 'ResumeCritiquePage',
        object_type: 'Page',
        specific_object: 'Landed on ResumeCritiquePage page'
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true
      }).catch(err => console.error("🔥 Log Activity Error:", err));
    } else {
      console.warn("⚠️ sessionId missing: auth context not ready yet.", auth);
    }
  }, [auth]);
   

  // ======== MOUNT / UNMOUNT ========
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      //console.log("❌ Unmounting ResumeCritiquePage...");
    };
  }, []);

  useEffect(() => {
    //console.log(`🚀 Mounted ResumeCritiquePage for application ID: ${applicationId}`);
    return () => {
      //console.log("❌ Unmounting ResumeCritiquePage...");
    };
  }, [applicationId]);

  // ======== Fetching job data ========
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        setErrorMessage("");
  
        const response = await axios.get(`/critique/${applicationId}?sessionId=${auth.sessionId}`);

        if (!response.data.success) {
          console.error("API request unsuccessful:", response.data);
          setErrorMessage("No data returned from the backend.");
          return;
        }


        if (response.data.success) {
          const {
            job_title,
            job_requirements,
            original_resume_text,
            company_name,
            pay_range,
            location
          } = response.data;
  
          // Store them in state
          setJobTitle(job_title);
          setJobRequirements(job_requirements);
          setResumeText(original_resume_text);
          setCompanyName(company_name);
          setLocation(location);
  
              // If there's any pay_range, parse it or store it directly
              if (pay_range) {
                // Simple approach: store as one string
                // setSalaryDataMin(pay_range); // Or parse if it has a dash, e.g. "80k - 100k"
                
                // Example parse if the pay_range is something like "80k-100k"
                const match = pay_range.match(/(\S+)\s*-\s*(\S+)/);
                if (match) {
                  setSalaryDataMin(match[1]);
                  setSalaryDataMax(match[2]);
                } else {
                  // If no dash, just store as min and leave max as "N/A"
                  setSalaryDataMin(pay_range);
                }
              }
          // ✅ Call fetchCritiqueFromOpenAI once we have jobRequirements and resumeText
          if (job_requirements && original_resume_text) {
            fetchCritiqueFromOpenAI(job_requirements, original_resume_text);
          }
        } else {
          setErrorMessage("No data returned from the backend.");
        }
      } catch (error) {
        console.error("🔥 Error fetching job data:", error);
        setErrorMessage("Failed to load job data.");
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [applicationId]);
  
  

  // ======== Critique from OpenAI ========
  async function fetchCritiqueFromOpenAI(jobRequirements, resumeText) {
    if (!jobRequirements || !resumeText) {
      setErrorMessage("Missing job requirements or resume.");
      return;
    }
    setLoading(true);
    try {
      const openAIResponse = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are an AI career expert critiquing resumes for job applications."
            },
            {
              role: "user",
              content: `
Critique the following resume for the job requirements below:

**Job Requirements:** ${jobRequirements}
**Resume:** ${resumeText}

Provide a critique as a structured JSON array where each score is an integer between 0 and 100:
[
  {"factor": "Relevance", "score": <0..100>, "reason": "<brief reason>"},
  {"factor": "Clarity", "score": <0..100>, "reason": "<brief reason>"},
  {"factor": "Grammar", "score": <0..100>, "reason": "<brief reason>"},
  {"factor": "ATS Optimization", "score": <0..100>, "reason": "<brief reason>"},
  {"factor": "Professionalism", "score": <0..100>, "reason": "<brief reason>"}
]
Only return the JSON array, nothing else.
`
            }
          ],
          temperature: 0.1, // keep this 0.1 so we have no randomness and the scores will alwats be the same
          max_tokens: 500
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_API_KEY}`
          }
        }
      );

      let rawCritiqueData = openAIResponse.data.choices[0].message.content;
      const jsonMatch = rawCritiqueData.match(/\[.*\]/s);
        if (!jsonMatch) {
        throw new Error("❌ Could not extract JSON data.");
        }

        let cleanJsonData = jsonMatch[0]
        .replace(/,\s*]/g, "]") // Remove trailing commas before the closing bracket
        .trim();

        //console.log("📜 Cleaned Critique Data:", cleanJsonData);

        try {
        const critiqueData = JSON.parse(cleanJsonData);
        //console.log("🔍 Parsed Critique Data:", critiqueData);
        setCritiqueFactors(critiqueData);
        } catch (error) {
        console.error("🔥 JSON Parse Error:", error);
        setErrorMessage("Error processing critique data.");
        }

    } catch (error) {
      console.error("🔥 Error fetching critique from OpenAI:", error);
      setErrorMessage("Failed to get critique from AI.");
    } finally {
      setLoading(false);
    }
  }

  // ======== Once we have the critique factors, start the factor animation ========
  useEffect(() => {
    if (critiqueFactors.length > 0 && displayedFactors.length === 0) {
      startCritiqueProcess();
    }
  }, [critiqueFactors]);

  // ======== Animate the Critique Factors ========
  function startCritiqueProcess() {
    setDisplayedFactors([]);
    setCurrentFactorIndex(0);
    setIsAnimatingFactor(true);
    setTimeout(() => {
      simulateCritiqueProcessLoop(0);
    }, 500);
  }

  function simulateCritiqueProcessLoop(index) {
    /* console.log(
      `simulateCritiqueProcessLoop called for index ${index} of ${critiqueFactors.length}`
    ); */
  
    // If we've animated all factors, compute final average & show sales pitch
    if (index >= critiqueFactors.length) {
      //console.log("✅ All factors are done. Computing final average...");
  
      // Compute final average
      const totalScore = critiqueFactors.reduce(
        (acc, item) => acc + (parseInt(item.score, 10) || 0),
        0
      );
      const avg = critiqueFactors.length
        ? Math.round(totalScore / critiqueFactors.length)
        : 0;
  
      // Show that final average on the main speedometer
      setCurrentScore(avg);
      setAverageScore(avg);
  
      //console.log("Final average score:", avg);
      //console.log("Triggering Sales Pitch in 1 second...");
  
      // Show the sales pitch modal after a short delay
      //setTimeout(() => {
      //  fetchSalesPitch(critiqueFactors);
    //  }, 1000);
  
      return;
    }
  
    // Animate this factor
    const factor = critiqueFactors[index];
    //console.log("Animating factor:", factor);
  
    // First, reset main speedometer to 0 for a clean start
    setCurrentScore(0);
  
    const rawScore = parseInt(factor.score, 10) || 0;
    const finalScore = Math.min(rawScore, 100);
    const duration = 3000; // 3 seconds
    const startTime = Date.now();
  
    // Keep updating every 200 ms
    const scoreInterval = setInterval(() => {
      if (!isMountedRef.current) {
        //console.log("Component unmounted; clearing interval.");
        clearInterval(scoreInterval);
        return;
      }
  
      const elapsed = Date.now() - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const factorAnimatedScore = Math.floor(progress * finalScore);
  
      // Update the main speedometer to show partial factor score
      setCurrentScore(factorAnimatedScore);
  
      // Update displayedFactors with partial score (for text display)
      setDisplayedFactors((prev) => {
        const updated = [...prev];
        if (!updated[index]) {
          // Create the factor object if it doesn't exist yet
          updated[index] = { ...factor, animatedScore: 0, showCard: false };
        }
        updated[index].animatedScore = factorAnimatedScore;
        return updated;
      });
  
      // Once we've fully animated this factor
      if (progress >= 1) {
        /* console.log(
          `Factor ${index} done animating. Final factor score = ${finalScore}`
        ); */
        clearInterval(scoreInterval);
  
        // Show the factor card
        setDisplayedFactors((prev) => {
          const updated = [...prev];
          updated[index] = {
            ...updated[index],
            showCard: true,
          };
          return updated;
        });
  
        // Move to the next factor after a short delay
        setTimeout(() => {
          simulateCritiqueProcessLoop(index + 1);
        }, 1000);
      }
    }, 200);
  }
  
  
  
  

  // ======== Sales Pitch ========
  async function fetchSalesPitch(critiqueData) {
    try {
      // Example: compute missingElements & averageScore from AI or your logic
      const totalScore = critiqueData.reduce(
        (acc, item) => acc + (item.score || 0),
        0
      );
      const avgScore = critiqueData.length
        ? Math.round(totalScore / critiqueData.length)
        : 0;
      setAverageScore(avgScore);

      // Suppose "missingElements" is derived from your logic or just "some data"
      setMissingElements("Leadership experience, advanced react scanning projects");

      // Finally, show the sales pitch modal
      setShowSalesPitchModal(true);
    } catch (error) {
      console.error("🔥 Error generating sales pitch:", error);
    }
  }

  // ======== Render ========
  return (
    <div>
      <div className="critique-page">
        {/* LEFT MODULE */}
        <div className="left-module">
        <h2>Job <span className="highlight">Details</span></h2>
        <p><strong>Job Title:</strong> {jobTitle || "N/A"}</p>
        <p><strong>Company:</strong> {companyName || "N/A"}</p>
        <p><strong>Location:</strong> {location || "N/A"}</p>
        <p><strong>Salary Range: </strong> 
            {salaryDataMax && salaryDataMax !== "N/A"
            ? `${salaryDataMin} - ${salaryDataMax}`
            : salaryDataMin}
        </p>
        </div>


        {/* RIGHT MODULE */}
        <div className="right-module">
          <h1>Resume  <span className="highlight">Critique</span></h1>
          {loading && <p>Loading...</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {/* Overall Score or Analyzing Animation */}
            {critiqueFactors.length === 0 ? (
            <div className="analyzing-animation">
                <p>Analyzing resume, please wait...</p>
                <div className="spinner"></div>
            </div>
            ) : (
            <div style={{ marginBottom: "20px" }}>
                <h3>Your Overall Score</h3>
                {/* Use currentScore for dynamic updates during animation */}
                <CircularProgress score={currentScore} />
            </div>
            )}

            {displayedFactors.length > 0 && displayedFactors.some(factor => factor.showCard === true) ? (
              <button 
                onClick={() => navigate(`/DocumentBuilderPage/${applicationId}`)}
                className="optimize-button"
              >
                <span className="optimize-button-icon">⚡</span>
                Optimize Resume & Generate AI Documents
              </button>
            ) : null}



          {/* Factor Cards */}
          <div className="completed-factors-grid">
            {displayedFactors.map((f, i) => (
              <FactorCard
                key={i}
                factor={f.factor}
                reason={f.reason}
                score={f.animatedScore}  // We'll color-code based on this
                show={f.showCard}
              />
            ))}
          </div>

          <ProgressBar
            currentStep={3}
            totalSteps={4}
            stepLabels={["Upload Resume", "Job Details", "Critique", "Optimize / Finalize"]}
            stepPaths={[
              "/UploadResume",
              "/JobDetails",
              `/critique/${auth.applicationId}`,
              "/optimize"
            ]}
          />
        </div>
      </div>

      <FeaturedEmployers />
      <SocialProof />

 
    </div>
  );
}

export default ResumeCritiquePage;
