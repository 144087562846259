import React, { useState, useEffect, useContext } from 'react';
import axios from "../api/axios";
import AuthContext from "../context/AuthProvider";
import './StripeSubscriptionButton.css'; // Create this CSS file for styling

const StripeSubscriptionButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [hasHadTrialBefore, setHasHadTrialBefore] = useState(false);
  const { auth } = useContext(AuthContext);
  
  // Get the critique ID from the URL if it exists
  const location = window.location.pathname;
  const critiqueid = location.includes('/critique/') ? 
    location.split('/critique/')[1] : null;
  
  // Get the full current URL for returning to the same page
  const returnUrl = window.location.href;

  // Check if user has an active subscription and if they've had a trial before
  useEffect(() => {
    const checkSubscriptionHistory = async () => {
      try {
        if (auth.sessionId) {
          //console.log("Checking subscription status for user:", auth.username);
          
          // First check current subscription
          const response = await axios.post('/get-subscription', {
            sessionId: auth.sessionId
          });
          
          //console.log("Subscription response:", response.data);
          
          const subscription = response.data;
          setSubscriptionInfo(subscription);
          setHasSubscription(subscription && subscription.status === 1);
          
          // Check subscription history - specifically for trial history
          //console.log("Checking subscription history");
          const historyResponse = await axios.post('/check-subscription-history', {
            sessionId: auth.sessionId
          });
          
          //console.log("History response:", historyResponse.data);
          
          // Multiple checks to determine if user has had a trial before
          const hadTrial = 
            // Check the direct response from backend
            historyResponse.data.hadTrial || 
            // Check active subscription trial info
            subscription  ||
            // Additional check for any subscription history entry
            historyResponse.data.hasHistory;
            
          
          //console.log("Has had trial before:", hadTrial);
          setHasHadTrialBefore(hadTrial);
        }
      } catch (err) {
        console.error("Error checking subscription status or history:", err);
      }
    };
  
    checkSubscriptionHistory();
  }, [auth.sessionId, auth.username]);

  // Handle subscription button click
  const handleSubscribe = async () => {
    try {
      setIsLoading(true);

      axios.post('/log-activity', {  sessionId: auth.sessionId,event_type:"Button Press",current_page:'StripeSubscriptionButton' ,object_type:'Page',specific_object:'Attempted to press subscribe button' }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      });
      
      // Determine if user should get a trial based on their history
      const trialDays = hasHadTrialBefore ? 0 : 7;
      
      //console.log(`Starting subscription with ${trialDays} trial days`);
      
      // Create a subscription with a trial only for first-time subscribers
      const response = await axios.post('/create-stripe-subscription', {
        sessionId: auth.sessionId,
        plan: 'premium',
        cost: 12.99,
        billingInterval: 'month',
        trialDays: trialDays, // 0 for returning users, 7 for new users
        critiqueid: critiqueid,
        returnUrl: returnUrl
      });
      
      // Redirect to Stripe Checkout
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error("No checkout URL returned");
      }
    } catch (err) {
      console.error("Error creating subscription:", err);
      alert("Failed to start subscription process. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle subscription cancellation
  const handleCancelSubscription = async () => {
    if (!subscriptionInfo || !subscriptionInfo.subscriptionId) return;
    
    try {
      setIsLoading(true);
      
      // If subscription ID starts with "sub_", it's a Stripe subscription
      if (subscriptionInfo.subscriptionId.startsWith('sub_') || subscriptionInfo.subscriptionId.startsWith('cs_')) {
        await axios.post('/cancel-stripe-subscription', {
          sessionId: auth.sessionId,
          subscriptionId: subscriptionInfo.subscriptionId
        });
      } else {
        // Use existing cancel method for PayPal/other
        await axios.post('/cancel-subscription', {
          sessionId: auth.sessionId,
          subId: subscriptionInfo.subscriptionId
        });
      }
      
      // Refresh subscription info
      const response = await axios.post('/get-subscription', {
        sessionId: auth.sessionId
      });
      
      setSubscriptionInfo(response.data);
      setHasSubscription(response.data && response.data.status === 1 && !response.data.cancel_subscription);
      
      // Show success message
      alert("Your subscription has been canceled. You'll have access until the end of your billing period.");
    } catch (err) {
      console.error("Error canceling subscription:", err);
      alert("Failed to cancel subscription. Please try again or contact support.");
    } finally {
      setIsLoading(false);
    }
  };

  // If subscription exists but is marked for cancellation
  if (hasSubscription && subscriptionInfo && subscriptionInfo.cancel_subscription === 1) {
    return (
      <div className="subscription-status-pro">
        <div className="status-content">
          <span className="status-icon">
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 7H13V13H11V7ZM11 15H13V17H11V15Z" fill="currentColor"/>
            </svg>
          </span>
          <span className="status-text">Premium (Canceling at period end)</span>
        </div>
      </div>
    );
  }
  
  // If user already has an active subscription
  if (hasSubscription) {
    return (
      <div className="subscription-container-pro">
        <div className="premium-badge-pro">
          <svg className="checkmark-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7L19.59 5.59L9 16.17Z" fill="currentColor"/>
          </svg>
          <span>Premium Active</span>
        </div>
        <button 
          className="cancel-subscription-btn-pro" 
          onClick={handleCancelSubscription}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="btn-loading-pro">
              <div className="spinner-pro"></div>
            </div>
          ) : 'Cancel Subscription'}
        </button>
      </div>
    );
  }

  // Show the subscription button for non-subscribers with appropriate text
  return (
    <button 
      className="subscription-btn-pro" 
      onClick={handleSubscribe}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className="btn-loading-pro">
          <div className="spinner-pro"></div>
          <span>Processing...</span>
        </div>
      ) : (
        <>
          <svg className="lock-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 16.1 13.1 17 12 17ZM15.1 8H8.9V6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8Z" fill="currentColor"/>
          </svg>
          <span>
            {hasHadTrialBefore ? 'Upgrade to Premium' : 'Start 7-Day Free Trial'}
          </span>
        </>
      )}
    </button>
  );
};

export default StripeSubscriptionButton;