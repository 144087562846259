import React, { useState,useEffect,useContext } from 'react';
import axios from './api/axios';
import AuthContext from "./context/AuthProvider";
import storeActivity from './functions/storeActivity.jsx';

import { Link } from 'react-router-dom';


import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';
import useWindowDimensions from './functions/useWindowDimensions.jsx'
import { set } from 'js-cookie';




const BuyCredits = () => {

  const [buyCredits, setBuyCredits] = useState(0);  
  const [buyCost, setBuyCost] = useState(0.00);  
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState(0);
  const [loggedIn, setLoggedIn] = useState('');
  const [sessionId, setSessionId] = useState('');
  const { auth,setAuth } = useContext(AuthContext);
  const [planId,setPlanId] = useState('');

  const [showStripe,setShowStripe] = useState(false);
  const { height, width } = useWindowDimensions();

  const payPalClientId = process.env.REACT_APP_PAY_PAL_API_KEY
  useEffect(() => {   
    
    setUsername(localStorage.getItem('username'));
    setCredits(localStorage.getItem('credits'));
    setLoggedIn(localStorage.getItem('loggedIn'));
    setSessionId(localStorage.getItem('sessionId'));
  }, []);

  useEffect(() => {
    // ...
  }, [planId]); // add planId as a dependency

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'BuyCredits'             ,'Page'              ,'Landed on BuyCredits page');
  }, []);

  const prices = {
    1: 12.99,
    //2:0.01,
    2: 14.99,
    3: 29.99,
    4: 119.99,
  };

  const plans = {
    1: 'P-5144885460270311GMPU5JVI',
    //2:0.01,
    2: 'P-1MG54108RM420493NMPU5JOA',
    3: 'P-8LN17015WT358443VMPU5HNY',
    4: 'P-5L601918AY002913AMQBWVDA',
  };

  const stripeID = {
    2: { id: 1, quantity: 1 },
    //2:0.01,
    5: { id: 2, quantity: 1 },
    10: { id: 3, quantity: 1 },
    20: { id: 4, quantity: 1 },
    50: { id: 5, quantity: 1 },
  };


  const sendEmailServerSide = async (email, coverLetter,emailType,documentType) => {
    try {
      await axios.post('/send-email', {sessionId:auth.sessionId,email:email,message:coverLetter,emailType:emailType,documentType:documentType},{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      })
    } catch (error) {
      //console.error(error);
    }
  };



  const handleCreditSelection = (event) => {
    let plan = plans[event.target.value];  
    setPlanId(plan);
    //console.log(planId);
  }

  return (
    //<div className="container">
    <div>
    <header>
      {/* <h1 className="text-center">Subscribe</h1> */}
    </header>
    <h2 className="text-center"><span style={{fontWeight:"bold"}}>SUBSCRIBE NOW</span></h2>
    <br/>
    {/* <div className="row"> */}
      {/* <div className="col-md-8">
       <p style={{}}>Select subscription option below:</p>
        <div className=" btn-group-toggle" style={{}}>
 
          <br />
          <p>Access to all premium site tools , with 25 credit limit </p>
          <label className="btn  buyCredits btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="1" onChange={handleCreditSelection} />
            Basic Plan - <span className="price">$40</span> <span className="discounted-price">${prices[1]}</span>
          </label>
          <br />
          <p>Access to all premium site tools , with 50 credit limit</p>
          <label className="btn buyCredits btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="2" onChange={handleCreditSelection} />
            Advantage Plan - <span className="price">$80</span> <span className="discounted-price">${prices[2]}</span>
          </label>
          <br />
          <p>Access to all premium site tools , with 100 credit limit</p>
          <label className="btn buyCredits btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="3" onChange={handleCreditSelection} />
            Elite Plan - <span className="price">$160</span> <span className="discounted-price">${prices[3]}</span>
          </label>

          <br />
          <br />
        </div>
      </div> */}

<div style={{ maxWidth: '100%' }} >
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
{/*             <Grid item>
              <Paper
                sx={{
                  height: 300,
                  width: 300,
                  padding: "20px",
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                  boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
                  borderRadius: 10,
                  transition: 'all 0.2s ease-in-out',
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              > 
                <Typography level="h3" fontSize="xl" fontWeight="bold" sx={{ mb: 0.5, mt: 1.5 }}>
                  Cover Letter Genius
                </Typography>
                <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
                  <li className="mx-auto text-left">Generates customized cover letters</li>
                  <li className="mx-auto text-left">Uses AI technology to analyze job descriptions</li>
                  <li className="mx-auto text-left">Provides a well-rounded image to potential employers</li>
                </ul>
                <Link to="/ilder"  style={{ margin: '20px' }} className="btn btn-success mr-2 same-size-btn">Cover Letter AI</Link>
              </Paper>
            </Grid> */}

            <Grid item>
              <Paper
                sx={{
                  //height: 400,
                  height: width > 700 ? 400 : 320,
                  //width: 600,
                  width: width > 700 ? 600 : "100%",

                  padding: "10%",
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                  boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
                  borderRadius: 10,
                  transition: 'all 0.2s ease-in-out',
                  //display: "flex",
                  //flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexGrow: 1
                }}
              > 
                <h1 className="text-center"><span style={{fontWeight:"bold"}}>Unlock access to all premium site tools</span></h1>
                 {/* <p className="text-center">Access to all premium site tools , with 25 credit limit </p> */}
                  {/* <label className="btn  buyCredits btn-outline-primary btn-block"> */}
                    {/* <input type="radio" name="buyCredits" value="1" onChange={handleCreditSelection} /> */}
                    {/* <p>Basic Plan - <span className="price">$40</span> <span className="discounted-price">${prices[1]}</span></p> */}
                  {/* </label> */}
     {/*            <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
                  <li className="mx-auto text-left">Generates customized resumes</li>
                  <li className="mx-auto text-left">Uses AI technology to analyze job descriptions</li>
                  <li className="mx-auto text-left">Optimizes resumes to get past <span className="stickout">ATS filters</span></li>
                </ul> */}
                <h1 className="text-center"><span style={{}}>${prices[1]}/Monthly</span></h1>
             

                
                {/* <Link to="/ResumeTools"  style={{ margin: '20px' }} className="btn btn-success mr-2 same-size-btn">Resume AI Tools</Link> */}
                {/* <img src={resumeImage} alt="Resume AI" sx={{ mt: 1.5, mb: 1.5 }} /> */}
              </Paper>
            </Grid>
{/*             <Grid item>
              <Paper
                sx={{
                  //height: 400,
                  height: width > 700 ? 400 : 320,
                  width: width > 700 ? 600 : "100%",
                  padding: "10%",
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                  boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
                  borderRadius: 10,
                  transition: 'all 0.2s ease-in-out',
                  //display: "flex",
                  //flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexGrow: 1
                }}
              > 
                <h1 className="text-center"><span style={{fontWeight:"bold"}}>Unlock access to all premium site tools</span></h1>
           

<h1 className="text-center"> <span className="price">$155.88</span> <span className="discounted-price">${prices[4]}/Yearly</span></h1>


        
              </Paper>
            </Grid> */}
            </Grid>
            </Grid>
            </Grid>

            </div>
   
      {/* <div className="col-md-4"> */}
        {/* <p>Subscription plan: {planId} credits {planId >0 ? (<span>for ${prices[planId]} </span>):null}</p> */}
        {/* {planId != '' && (
          <div>
            <div key={planId}>
                <PayPalButton planId={planId}/>
            </div> */}
          
          {/* {showStripe ? <Button variant='outline' onClick={HandleStripePayment}> Pay With Stripe</Button> : null} */}
          {/* </div>
      )} */}
      
      {paymentStatus > 0 && <p>Payment successful! Thank you for your purchase.</p>}


      <footer>
    <p>{/* Don't need that many credits? You can also purchase a single credit for $2.00. */}</p>
    </footer>
    {/* </div> */}
    </div>
   
  );
}

export default BuyCredits
