import React, { useState, useEffect, useRef } from "react";
import "./CircularProgress.css";

function scoreColor(score) {
  if (score >= 80) return "#00cc88";
  if (score >= 60) return "#ffbb33";
  return "#ff4444";
}

function CircularProgress({ score }) {
  const [animatedScore, setAnimatedScore] = useState(0);
  const prevScoreRef = useRef(0);
  const animationRef = useRef(null);
  const startTimeRef = useRef(null);
  
  const radius = 60;
  const circumference = 2 * Math.PI * radius;
  
  useEffect(() => {
    // Store the current score before updating to the new target
    const prevScore = prevScoreRef.current;
    // Always start animation from current value to new target
    const startAnimation = (timestamp) => {
      if (!startTimeRef.current) startTimeRef.current = timestamp;
      const elapsed = timestamp - startTimeRef.current;
      const duration = 1500; // 1.5 seconds animation
      
      // Calculate progress (0 to 1) based on elapsed time
      const progress = Math.min(elapsed / duration, 1);
      
      // Calculate current score value using easeOutQuad easing function
      // This creates a more natural animation curve
      const easeOutQuad = (t) => t * (2 - t);
      const currentValue = prevScore + (score - prevScore) * easeOutQuad(progress);
      
      // Update the animated score
      setAnimatedScore(Math.round(currentValue));
      
      // Continue animation if not completed
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(startAnimation);
      } else {
        // Ensure final value matches target exactly
        setAnimatedScore(score);
        prevScoreRef.current = score;
        startTimeRef.current = null;
      }
    };
    
    // Cancel any ongoing animation
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    
    // Start new animation
    animationRef.current = requestAnimationFrame(startAnimation);
    
    // Clean up animation on unmount or when score changes
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [score]);
  
  // Calculate the progress circle offset based on the animated score
  const progress = Math.min(animatedScore, 100);
  const offset = circumference - (progress / 100) * circumference;
  
  return (
    <svg className="progress-ring" width="150" height="150" viewBox="0 0 150 150">
      {/* Background Circle */}
      <circle
        className="progress-ring__background"
        stroke="#e6e6e6"
        fill="transparent"
        strokeWidth="12"
        r={radius}
        cx="75"
        cy="75"
      />
      {/* Animated Progress Circle */}
      <circle
        className="progress-ring__progress"
        stroke={scoreColor(progress)}
        fill="transparent"
        strokeWidth="12"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        r={radius}
        cx="75"
        cy="75"
        transform="rotate(-90 75 75)"
        style={{
          transition: "stroke 0.5s ease-in-out",
        }}
      />
      {/* Score text in center */}
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="20px"
        fontWeight="bold"
        fill={scoreColor(progress)}
      >
        {progress}%
      </text>
    </svg>
  );
}

export default CircularProgress;