import React, { useContext,useState,useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import AuthContext from "./context/AuthProvider";
import ToolBar from './ToolBar.jsx';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import "./Footer.css";



const Footer = () => (
  <footer style={{
    backgroundColor: '#eee',
    padding: '20px',
    textAlign: 'center',
    width:'100%',
  }}>
    <div style={{ textAlign: 'center' }}>

        <p style={{fontSize: '14px',fontWeight:'bold'}}>Please Read</p>
        <p style={{fontSize: '14px'}}>
          Please note that the generated content is AI generated and may not be 100% accurate. Please read over the content and fix any errors before submitting it. We are not liable for any errors in the content, but if you have any questions or need assistance, please email us at <a href="mailto:careergeniusapp@gmail.com">careergeniusapp@gmail.com</a> and we will be happy to help in any way we can.
        </p>
      </div>
      <Link to="/about" style={{
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#333',
        textDecoration: 'none',
        marginRight: '20px',
      }}>About</Link>
      <Link to="/termsandconditions" style={{
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#333',
        textDecoration: 'none',
        marginRight: '20px',
      }}>Terms and Conditions</Link>
      <Link to="/contact" style={{
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#333',
        textDecoration: 'none',
        marginRight: '20px',
      }}>Contact</Link>
    <p style={{
      fontSize: '14px',
      color: '#333',
      margin: 0,
    }}>Copyright 2022 Career Genius</p>
  </footer>
);

export default Footer;
