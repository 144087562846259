// ChatGPTResumeParser.js
import axios from "axios";

export default class ResumeParser {
  constructor(resumeText) {
    // Same initialization as your old parser
    this.resumeText = resumeText || "";
    this.normalizedText = this.normalizeText(this.resumeText);

    // Keep the same default data structure
    this.data = {
      name: "",
      contactInfo: {
        email: "",
        phone: "",
        linkedin: "",
        website: "",
        location: ""
      },
      summary: "",
      skills: [],
      experience: [],
      education: [],
      projects: [],
      certifications: []
    };

    // Optional: read your API key from environment
    this.apiKey = process.env.REACT_APP_GPT_API_KEY;
  }

  // Keep a normalize method (optional, if you want to feed GPT a cleaner text)
  normalizeText(text) {
    if (!text) return "";
    return text
      .replace(/\r\n/g, "\n") // normalize line breaks
      .replace(/\n{3,}/g, "\n\n") // remove excess line breaks
      .trim();
  }

  /**
   * Main parse method (async):
   *  - Calls ChatGPT with your resume text
   *  - Returns a data object with the same structure as before
   */
  async parse() {
    try {
      //console.log("Starting resume parsing with ChatGPT...");

      // 1) Call GPT to parse the resume
      const parsedData = await this.fetchDataFromGPT(this.normalizedText);

      // 2) Merge GPT result into this.data (so we keep the same shape)
      //    If GPT returns missing fields, they stay as defaults.
      this.data = {
        ...this.data,
        ...parsedData,
        contactInfo: {
          ...this.data.contactInfo,
          ...parsedData.contactInfo
        }
      };

      //console.log("Resume parsing complete (GPT).");
      return this.data;
    } catch (err) {
      console.error("Error parsing resume with ChatGPT:", err);
      // Fallback: return the default empty structure
      return this.data;
    }
  }

  /**
   * Calls the OpenAI API with a prompt instructing it to return
   * a JSON structure that matches our existing data shape.
   */
  async fetchDataFromGPT(resumeText) {
    if (!this.apiKey) {
      throw new Error("OpenAI API key not found in REACT_APP_GPT_API_KEY");
    }

    if (!resumeText.trim()) {
      // No content to parse
      return this.data;
    }

    // Instruct ChatGPT to output strict JSON with the fields you want
    const systemPrompt = `
You are a resume parser. 
Given a plain-text resume, you will extract the following fields and return them in strict JSON (no extra text, no markdown):
{
  "name": "",
  "contactInfo": {
    "email": "",
    "phone": "",
    "linkedin": "",
    "website": "",
    "location": ""
  },
  "summary": "",
  "skills": [],
  "experience": [],
  "education": [],
  "projects": [],
  "certifications": []
}

Details:
- "experience" is an array of objects with { "title", "company", "dates", "location", "responsibilities" }
- "education" is an array of objects with { "degree", "school", "dates", "gpa", "achievements" }
- "projects" is an array of objects with { "name", "description", "technologies" }
- "certifications" is an array of objects with { "name", "issuer", "date" }
- "skills" is an array of strings
- Return only valid JSON with these keys. If a key is missing, leave it empty or as an empty array.
- Do not include any extra text or explanations.

Now parse this resume:
"""${resumeText}"""
    `.trim();

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: systemPrompt }
        ],
        temperature: 0,   // to make output more deterministic
        max_tokens: 1000  // enough tokens for typical resumes
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.apiKey}`
        }
      }
    );

    if (!response.data?.choices?.length) {
      throw new Error("No response from ChatGPT");
    }

    const rawContent = response.data.choices[0].message.content.trim();
    let parsed;
    try {
      parsed = JSON.parse(rawContent);
    } catch (jsonErr) {
      console.error("Could not parse JSON from GPT:", jsonErr);
      //console.log("GPT raw output was:", rawContent);
      throw new Error("GPT returned invalid JSON.");
    }

    return parsed;
  }
}
