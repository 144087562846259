// ----- Template Definitions -----
const TEMPLATES = [
    {
      id: "modern",
      templateName: "Modern Two-Column",
      previewImage: "/images/modern-resume.png",
      getHTML: () => `
        <div style="max-width: 8.5in; margin: auto; display: flex; border: 1px solid #ccc; font-size: 10pt; min-height: 11in; max-height: 11in; overflow: hidden;">
          <!-- LEFT COLUMN -->
          <div style="width: 30%; background: #f4f4f4; padding: 20px;">
            <h2 data-gjs-editable="true" style="color: #2c3e50; margin-top: 0;">Name Placeholder</h2>
            <p data-gjs-editable="true" data-section="contactInfo" style="font-size: 9pt; margin-bottom: 25px;">
              Email: <br>
              Phone: <br>
              LinkedIn:
            </p>
            <h3 style="color: #3498db; border-bottom: 1px solid #3498db; padding-bottom: 5px;">Skills</h3>
            <ul data-section="skills" data-gjs-editable="true" style="padding-left: 15px; margin-top: 10px; font-size: 9pt;">
              <li>Skill 1</li>
              <li>Skill 2</li>
              <li>Skill 3</li>
            </ul>
          </div>
          <!-- RIGHT COLUMN -->
          <div style="width: 70%; padding: 20px;">
            <h3 style="color: #3498db; border-bottom: 1px solid #3498db; padding-bottom: 5px;">Summary</h3>
            <p data-section="summary" data-gjs-editable="true" style="font-size: 9pt; margin-bottom: 15px;">
              placeholder summary text
            </p>
            <h3 style="color: #3498db; border-bottom: 1px solid #3498db; padding-bottom: 5px;">Experience</h3>
            <div data-section="experience" data-gjs-editable="true" style="font-size: 9pt; margin-bottom: 15px;">
              <h4 style="margin-bottom: 5px; margin-top: 10px;">Company - Role</h4>
              <p style="margin-top: 3px;">Dates & responsibilities</p>
            </div>
            <h3 style="color: #3498db; border-bottom: 1px solid #3498db; padding-bottom: 5px;">Education</h3>
            <div data-section="education" data-gjs-editable="true" style="font-size: 9pt; margin-bottom: 15px;">
              <h4 style="margin-bottom: 5px; margin-top: 10px;">Institution - Degree</h4>
              <p style="margin-top: 3px;">Dates & achievements</p>
            </div>
            <h3 style="color: #3498db; border-bottom: 1px solid #3498db; padding-bottom: 5px;">Projects</h3>
            <div data-section="projects" data-gjs-editable="true" style="font-size: 9pt;">
              <h4 style="margin-bottom: 5px; margin-top: 10px;">Project Name</h4>
              <p style="margin-top: 3px;">Description, link, etc.</p>
            </div>
          </div>
        </div>
      `
    },
    {
      id: "classic",
      templateName: "Classic (Single Column)",
      previewImage: "/images/classic-resume.png",
      getHTML: () => `
        <div style="max-width: 8.5in; margin: auto; padding: 40px; border: 1px solid #ccc; font-size: 10pt; min-height: 11in; max-height: 11in; overflow: hidden;">
          <!-- HEADER -->
          <div style="text-align: center; margin-bottom: 20px;">
            <h1 data-gjs-editable="true" style="margin-bottom: 10px;">Name Placeholder</h1>
            <p data-gjs-editable="true" data-section="contactInfo" style="font-size: 9pt;">
              Email: | Phone: | LinkedIn:
            </p>
          </div>
          
          <!-- SUMMARY -->
          <div style="margin-bottom: 15px;">
            <h3 style="border-bottom: 1px solid #000; padding-bottom: 5px;">Summary</h3>
            <p data-section="summary" data-gjs-editable="true" style="font-size: 9pt;">
              placeholder summary text
            </p>
          </div>
          
          <!-- EXPERIENCE -->
          <div style="margin-bottom: 15px;">
            <h3 style="border-bottom: 1px solid #000; padding-bottom: 5px;">Experience</h3>
            <div data-section="experience" data-gjs-editable="true" style="font-size: 9pt;">
              <h4 style="margin-bottom: 3px; margin-top: 10px;">Company - Role</h4>
              <p style="margin-top: 3px;">Dates & responsibilities</p>
            </div>
          </div>
          
          <!-- EDUCATION -->
          <div style="margin-bottom: 15px;">
            <h3 style="border-bottom: 1px solid #000; padding-bottom: 5px;">Education</h3>
            <div data-section="education" data-gjs-editable="true" style="font-size: 9pt;">
              <h4 style="margin-bottom: 3px; margin-top: 10px;">Institution - Degree</h4>
              <p style="margin-top: 3px;">Dates & achievements</p>
            </div>
          </div>
          
          <!-- SKILLS -->
          <div style="margin-bottom: 15px;">
            <h3 style="border-bottom: 1px solid #000; padding-bottom: 5px;">Skills</h3>
            <ul data-section="skills" data-gjs-editable="true" style="padding-left: 20px; margin-top: 10px; font-size: 9pt; columns: 2;">
              <li>Skill 1</li>
              <li>Skill 2</li>
              <li>Skill 3</li>
              <li>Skill 4</li>
            </ul>
          </div>
          
          <!-- PROJECTS -->
          <div>
            <h3 style="border-bottom: 1px solid #000; padding-bottom: 5px;">Projects</h3>
            <div data-section="projects" data-gjs-editable="true" style="font-size: 9pt;">
              <h4 style="margin-bottom: 3px; margin-top: 10px;">Project Name</h4>
              <p style="margin-top: 3px;">Description, link, etc.</p>
            </div>
          </div>
        </div>
      `
    },
    {
      id: "minimalist",
      templateName: "Minimalist",
      previewImage: "/images/minimalist-resume.png",
      getHTML: () => `
        <div style="max-width: 8.5in; margin: auto; padding: 40px; border: 1px solid #ccc; font-size: 10pt; min-height: 11in; max-height: 11in; overflow: hidden; font-family: Arial, sans-serif;">
          <!-- HEADER -->
          <div style="margin-bottom: 30px;">
            <h1 data-gjs-editable="true" style="font-size: 20pt; margin-bottom: 10px; font-weight: 300; color: #333;">Name Placeholder</h1>
            <p data-gjs-editable="true" data-section="contactInfo" style="font-size: 9pt; color: #666;">
              Email: | Phone: | LinkedIn:
            </p>
          </div>
          
          <!-- TWO COLUMN LAYOUT -->
          <div style="display: flex; gap: 30px;">
            <!-- LEFT COLUMN (70%) -->
            <div style="flex: 7;">
              <!-- SUMMARY -->
              <div style="margin-bottom: 25px;">
                <h2 style="font-size: 12pt; color: #333; letter-spacing: 1px; margin-bottom: 10px; font-weight: 400;">SUMMARY</h2>
                <p data-section="summary" data-gjs-editable="true" style="font-size: 9pt; line-height: 1.4; color: #444;">
                  placeholder summary text
                </p>
              </div>
              
              <!-- EXPERIENCE -->
              <div style="margin-bottom: 25px;">
                <h2 style="font-size: 12pt; color: #333; letter-spacing: 1px; margin-bottom: 10px; font-weight: 400;">EXPERIENCE</h2>
                <div data-section="experience" data-gjs-editable="true" style="font-size: 9pt;">
                  <div style="margin-bottom: 15px;">
                    <h3 style="margin: 0; font-size: 10pt; font-weight: 600;">Company - Role</h3>
                    <p style="margin: 3px 0; color: #666; font-size: 8pt;">Month Year – Present</p>
                    <p style="margin-top: 5px; color: #444; line-height: 1.4;">Dates & responsibilities</p>
                  </div>
                </div>
              </div>
              
              <!-- EDUCATION -->
              <div style="margin-bottom: 25px;">
                <h2 style="font-size: 12pt; color: #333; letter-spacing: 1px; margin-bottom: 10px; font-weight: 400;">EDUCATION</h2>
                <div data-section="education" data-gjs-editable="true" style="font-size: 9pt;">
                  <h3 style="margin: 0; font-size: 10pt; font-weight: 600;">Institution - Degree</h3>
                  <p style="margin: 3px 0; color: #666; font-size: 8pt;">Year - Year</p>
                  <p style="margin-top: 5px; color: #444; line-height: 1.4;">Dates & achievements</p>
                </div>
              </div>
            </div>
            
            <!-- RIGHT COLUMN (30%) -->
            <div style="flex: 3;">
              <!-- SKILLS -->
              <div style="margin-bottom: 25px;">
                <h2 style="font-size: 12pt; color: #333; letter-spacing: 1px; margin-bottom: 10px; font-weight: 400;">SKILLS</h2>
                <ul data-section="skills" data-gjs-editable="true" style="list-style-type: none; padding: 0; margin: 0; font-size: 9pt;">
                  <li style="margin-bottom: 5px; color: #444;">Skill 1</li>
                  <li style="margin-bottom: 5px; color: #444;">Skill 2</li>
                  <li style="margin-bottom: 5px; color: #444;">Skill 3</li>
                </ul>
              </div>
              
              <!-- PROJECTS -->
              <div>
                <h2 style="font-size: 12pt; color: #333; letter-spacing: 1px; margin-bottom: 10px; font-weight: 400;">PROJECTS</h2>
                <div data-section="projects" data-gjs-editable="true" style="font-size: 9pt;">
                  <h3 style="margin: 0; font-size: 10pt; font-weight: 600;">Project Name</h3>
                  <p style="margin-top: 5px; color: #444; line-height: 1.4;">Description, link, etc.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      `
    }
  ];

  // ✅ Properly Export the TEMPLATES Array
  export default TEMPLATES;
  