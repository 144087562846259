import React, { useEffect, useContext } from "react";
import axios from "./api/axios";
import AuthContext from "./context/AuthProvider";

const Home = () => {
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    const checkUserResume = async () => {
      if (!auth.username || !auth.sessionId) {
        //console.log("User not logged in. Redirecting to Upload Resume...");
        window.location.replace("/UploadResume");
        return;
      }

      try {
        const response = await axios.post("/check-resume", {
          username: auth.username,
        });

        if (response.data.hasResume) {
          //console.log("Resume found! Redirecting to Job Details...");
          window.location.replace("/JobDetails");
        } else {
          //console.log("No resume found. Redirecting to Upload Resume...");
          window.location.replace("/UploadResume");
        }
      } catch (error) {
        console.error("Error checking resume:", error);
        window.location.replace("/UploadResume"); // Default to upload page on error
      }
    };

    checkUserResume();
  }, [auth]);

  return <div>Loading...</div>; // Temporary loading state while redirecting
};

export default Home;
