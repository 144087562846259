import React from "react";
import { motion } from "framer-motion";
import "./FeaturedEmployers.css";

// Sample logos - replace with your own images or SVG imports
import googleLogo from "./assets/logos/google.svg";
import appleLogo from "./assets/logos/apple.svg";
import microsoftLogo from "./assets/logos/microsoft.svg";
import amazonLogo from "./assets/logos/amazon.svg";
import netflixLogo from "./assets/logos/netflix.svg";
import disneyLogo from "./assets/logos/disney.svg";
import starbucksLogo from "./assets/logos/starbucks.svg";

function FeaturedEmployers({ inModal = false }) {
  // Full set of 7 logos
  const fullLogos = [
    { src: googleLogo, alt: "Google" },
    { src: appleLogo, alt: "Apple" },
    { src: microsoftLogo, alt: "Microsoft" },
    { src: amazonLogo, alt: "Amazon" },
    { src: netflixLogo, alt: "Netflix" },
    { src: disneyLogo, alt: "Disney" },
    { src: starbucksLogo, alt: "Starbucks" }
  ];

  // Smaller set of 3 logos for the modal
  const modalLogos = [
    { src: googleLogo, alt: "Google" },
    { src: amazonLogo, alt: "Amazon" },
    { src: disneyLogo, alt: "Disney" },
    { src: starbucksLogo, alt: "Starbucks" }
  ];

  // Choose which logos to display based on the `inModal` prop
  const selectedLogos = inModal ? modalLogos : fullLogos;

  // Container variants (stagger children)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.15 }
    }
  };

  // Each logo variant
  const logoVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.section
      className={`featured-employers-container ${inModal ? "modal-featured" : ""}`}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
     
        <motion.h2 className="featured-title" variants={logoVariants}>
           Built to help you stand out — no matter where you apply.
        </motion.h2>
     

      {/* Logos row */}
      <motion.div className="logos-row" variants={containerVariants}>
        {selectedLogos.map((logo, idx) => (
          <motion.img
            key={idx}
            src={logo.src}
            alt={logo.alt}
            variants={logoVariants}
            transition={{ duration: 0.4 }}
          />
        ))}
      </motion.div>
     {/*  <div style={{
      fontSize: '0.55rem',
      color: '#999',
      marginTop: '0.5rem',
      textAlign: 'center',
      maxWidth: '600px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}>
      <p>
        Logos shown are for illustrative purposes only. We are not affiliated with or endorsed by these companies.
      </p>
    </div> */}
    </motion.section>
  );
}

export default FeaturedEmployers;
