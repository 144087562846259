import React, { useEffect, useState } from "react";
import "./SalesPitchPage.css"; // Add styling if needed
import FeaturedEmployers from "../../FeaturedEmployers";
import SocialProof from "../../SocialProof";
import axios from "../../api/axios";
import StripeSubscriptionButton from '../StripeSubscriptionButton.jsx';
import AuthContext from "../../context/AuthProvider";

function scoreColor(score) {
  if (score >= 80) return "#00cc88";
  if (score >= 60) return "#ffbb33";
  return "#ff4444";
}

function SalesPitchPage({ onClose, averageScore, missingElements, applicationId, auth }) {
  const [salary, setSalary] = useState(0);


  
  useEffect(() => {
    if (auth?.sessionId) {
      axios.post('/log-activity', {
        sessionId: auth.sessionId,
        event_type: "Page Landing",
        current_page: 'SalesPitchPage',
        object_type: 'Page',
        specific_object: 'Sales Pitch modal popped up'
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true
      }).catch(err => console.error("🔥 Log Activity Error:", err));
    } else {
      console.warn("⚠️ sessionId missing: auth context not ready yet.", auth);
    }
  }, [auth]);
   
  

  useEffect(() => {
    async function fetchSalaryData() {
      try {
        // Fetch job data including salary range
        const response = await axios.get(
          `/critique/${applicationId}?sessionId=${auth.sessionId}`
        );

        if (response.data.success && response.data.pay_range) {
          // Parse the salary range and extract max salary
          const payRange = response.data.pay_range;
          const match = payRange.match(/(\d+)[k]?\s*-\s*(\d+)[k]?/);
          
          if (match) {
            // Get the max salary value
            let maxSalary = parseInt(match[2], 10);
            
            // Handle if salary is in thousands (has 'k' suffix)
            if (payRange.includes('k') || payRange.includes('K')) {
              maxSalary = maxSalary * 1000;
            }
            
            setSalary(maxSalary);
          } else {
            // Try to extract any number from the string as fallback
            const numberMatch = payRange.match(/\d+/);
            if (numberMatch) {
              let extractedSalary = parseInt(numberMatch[0], 10);
              if (payRange.includes('k') || payRange.includes('K')) {
                extractedSalary = extractedSalary * 1000;
              }
              setSalary(extractedSalary);
            } else {
              // Default fallback salary
              setSalary(100000);
            }
          }
        } else {
          // Default fallback salary
          setSalary(100000);
        }
      } catch (error) {
        console.error("Error fetching salary data:", error);
        // Default fallback salary
        setSalary(100000);
      }
    }

    fetchSalaryData();
  }, [applicationId, auth.sessionId]);

  return (
    <div className="modal-overlay show" style={{ backgroundColor: "rgba(0, 0, 0, 0.35)" }}>
      <div
        className="modal-content"
        style={{
          borderRadius: "10px",
          padding: "24px",
          backgroundColor: "#ffffff",
          maxWidth: "420px",
          margin: "0 auto",
          boxShadow: "0 8px 20px rgba(0,0,0,0.15)",
          textAlign: "center",
          position: "relative",
        }}
      >
        <button
          className="modal-close"
          onClick={onClose}
          aria-label="Close Modal"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "28px",
            height: "28px",
            background: "#f1f1f1",
            border: "none",
            borderRadius: "50%",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333",
            position: "absolute",
            top: "12px",
            right: "16px",
            cursor: "pointer",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            zIndex: 10,
          }}
        >
          ✕
        </button>
        <h2 style={{ color: "#2C3E50", fontWeight: 600, marginBottom: "0.8rem" }}>
          How Does Your Resume Really Stack Up?
        </h2>
        <p style={{ fontSize: "1rem", marginBottom: "1rem", color: "#444" }}>
          Your resume's overall score is <strong style={{ color: scoreColor(averageScore) }}>{averageScore}%</strong>
          {" "} (Industry Avg: <strong>88%</strong>).
        </p>
        <p style={{ fontSize: "0.95rem", marginBottom: "1.2rem", color: "#444" }}>
          Every week of delay can cost you <strong style={{ color: "#2E8B57" }}>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(salary / 52)}
          </strong> in missed salary!
        </p>
        <p style={{ fontSize: "0.95rem", marginBottom: "1.2rem", color: "#555" }}>
          Your resume is missing: <strong style={{ color: "#1E88E5" }}>{missingElements}</strong>
        </p>
        {/* Featured Employers */}
        <FeaturedEmployers inModal={true} />
        {/* Social Proof */}
        <SocialProof inModal={true} />
        {/* Call to Action */}
        <StripeSubscriptionButton/>
      </div>
    </div>
  );
}

export default SalesPitchPage;