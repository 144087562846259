// src/components/ResumeCritique/FactorCard.jsx
import React from "react";
import "./FactorCard.css";

function FactorCard({ factor, reason, score, show }) {
  // Decide color class based on score
  let colorClass = "";
  if (score < 60) colorClass = "low-score";
  else if (score < 80) colorClass = "medium-score";
  else colorClass = "high-score";

  return (
    <div
      className={`factor-card ${colorClass}`}
      style={{
        opacity: show ? 1 : 0,
        transform: show ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
      }}
    >
      <h3>{factor} ({score}%)</h3>
      <p>{reason}</p>
    </div>
  );
}

export default React.memo(FactorCard);
