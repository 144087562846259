// src/functions/AIResumeFormatter.js
import axios from "../api/axios";

class AIResumeFormatter {
  constructor(apiKey) {
    this.apiKey = apiKey;
    //console.log("AIResumeFormatter initialized, API key available:", !!this.apiKey);
  }

  /**
   * Format responsibility text into professional bullet points
   * with improved handling to prevent bullet point breaking issues
   * @param {string} responsibilities - Text to format into bullets
   * @returns {Promise<string>} - Formatted bullet points
   */
  async formatResponsibilities(text) {
    // If no text provided, return empty string
    if (!text || typeof text !== 'string' || text.trim().length === 0) {
      return '';
    }
    
    try {
      // Check if text already has bullet points
      const hasBullets = text.includes('•') || 
                          text.includes('-') || 
                          text.includes('*') ||
                          /^\d+\.\s/.test(text);
      
      if (hasBullets) {
        // Process text that already has bullet points
        return this.processExistingBullets(text);
      } else {
        // Create bullet points from text that doesn't have them
        return this.createBulletPoints(text);
      }
    } catch (err) {
      console.error('Error formatting responsibilities:', err);
      // Return original text if there's an error
      return text;
    }
  }

  /**
   * Process text that already contains bullet points
   * Ensures bullets are consistent and complete thoughts aren't broken
   * @param {string} text - Text with existing bullets
   * @returns {string} - Properly formatted bullet points
   */
  processExistingBullets(text) {
    // First, normalize line breaks
    const normalizedText = text.replace(/\r\n/g, '\n');
    
    // Split text into lines
    const lines = normalizedText.split(/\n/);
    let bulletPoints = [];
    let currentPoint = '';
    
    // Process each line to properly merge bullet points
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      
      // Skip empty lines
      if (!line) continue;
      
      // Check if this line starts with a bullet
      const startsWithBullet = /^[•\-*]|\d+\.\s/.test(line);
      
      if (startsWithBullet) {
        // If we have content in currentPoint, add it to bulletPoints
        if (currentPoint) {
          bulletPoints.push(currentPoint);
        }
        // Start a new bullet point
        currentPoint = line;
      } else if (currentPoint) {
        // This line continues a previous bullet point
        // Check if it appears to be a continuation (starts with lowercase or specific words)
        const seemsToBeContinuation = 
          /^[a-z]/.test(line) || 
          /^(and|or|but|as|with|to|by|for|in|on|at)(\s|$)/i.test(line) || 
          !line.endsWith('.'); // Lines without period are likely continuations
          
        if (seemsToBeContinuation) {
          currentPoint += ' ' + line;
        } else {
          // This looks like it should be a new bullet
          bulletPoints.push(currentPoint);
          currentPoint = '• ' + line;
        }
      } else {
        // First line doesn't start with a bullet
        currentPoint = '• ' + line;
      }
    }
    
    // Add the last bullet point if there is one
    if (currentPoint) {
      bulletPoints.push(currentPoint);
    }
    
    // Normalize all bullets to use the same symbol
    bulletPoints = bulletPoints.map(point => {
      // Replace any bullet marker with a standard one
      return point.replace(/^[•\-*]|\d+\.\s/, '• ');
    });
    
    // Ensure each bullet point is properly formed
    bulletPoints = this.refineBulletPoints(bulletPoints);
    
    return bulletPoints.join('\n');
  }

  /**
   * Create bullet points from text that doesn't have them
   * @param {string} text - Plain text without bullets
   * @returns {string} - Formatted bullet points
   */
  async createBulletPoints(text) {
    // If text is too short or no API key, use basic formatting
    if (text.length < 20 || !this.apiKey) {
      return this.basicFormatting(text);
    }
    
    try {
      //console.log("Attempting AI formatting with OpenAI");
      
      // First attempt to call OpenAI directly
      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content: "You are an expert resume writer. Convert paragraphs into professional bullet points."
              },
              {
                role: "user",
                content: `Convert the following job description paragraph into 3-5 professional, impactful bullet points for a resume. 
                Start each with a strong action verb, be concise, and focus on achievements and skills.
                IMPORTANT: Each bullet point must be a complete thought. Never break sentences in the middle.
                Use the format "• Action verb..." for each bullet point.
                
                Text to convert:
                ${text}`
              }
            ],
            temperature: 0.3,
            max_tokens: 600
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.apiKey}`
            },
            timeout: 10000 // 10 second timeout
          }
        );
        
        if (response.data && response.data.choices && response.data.choices[0].message) {
          const formattedText = response.data.choices[0].message.content.trim();
          //console.log("AI formatting successful, response length:", formattedText.length);
          
          // Process the AI response to ensure it's properly formatted
          return this.processExistingBullets(formattedText);
        } else {
          console.warn("OpenAI response missing expected data structure");
          throw new Error("Invalid API response structure");
        }
      } catch (apiError) {
        console.error("OpenAI API error:", apiError);
        //console.log("Falling back to basic formatting");
        // Continue to fallback method
      }
      
      // Fallback to basic formatting if OpenAI call fails
      return this.basicFormatting(text);
    } catch (err) {
      console.error("Error creating bullet points:", err);
      return this.basicFormatting(text);
    }
  }

  /**
   * Enhanced basic formatting if OpenAI call fails
   * @param {string} text - Text to format
   * @returns {string} - Manually formatted text
   */
  basicFormatting(text) {
    if (!text) return "• No responsibilities provided.";
    
    try {
      //console.log("Using enhanced basic formatting");
      
      // Split by sentences for more natural bullet points
      let sentences = text
        .replace(/([.!?])\s+/g, "$1|")
        .split("|")
        .filter(s => s.trim().length > 10)
        .map(s => s.trim());
      
      // If no good sentences found, split by commas or semicolons
      if (sentences.length <= 1) {
        sentences = text
          .split(/[,;]/)
          .filter(s => s.trim().length > 10)
          .map(s => s.trim());
      }
      
      // If still no good segments, make a single bullet
      if (sentences.length === 0) {
        return "• " + text;
      }
      
      // Select action verbs to prepend
      const actionVerbs = [
        "Developed", "Created", "Implemented", "Managed", "Led",
        "Designed", "Improved", "Achieved", "Coordinated", "Established",
        "Increased", "Reduced", "Streamlined", "Generated", "Delivered"
      ];
      
      // Group sentences into coherent bullet points (max 3-4)
      const bulletPoints = [];
      let currentBullet = "";
      
      sentences.forEach(sentence => {
        // Skip if this sentence is very short
        if (sentence.length < 10) return;
        
        // If current bullet is empty, start a new one
        if (!currentBullet) {
          currentBullet = sentence;
        } 
        // If current bullet is getting long, start a new one
        else if (currentBullet.length > 100) {
          bulletPoints.push(currentBullet);
          currentBullet = sentence;
        } 
        // Otherwise, append this sentence to current bullet if it seems related
        else {
          const lowerSentence = sentence.toLowerCase();
          const lowerBullet = currentBullet.toLowerCase();
          
          // Check if this sentence seems to continue the previous one
          if (
            lowerSentence.startsWith("and") ||
            lowerSentence.startsWith("which") ||
            lowerSentence.startsWith("with") ||
            lowerSentence.startsWith("using") ||
            lowerBullet.includes(lowerSentence.split(' ')[0])
          ) {
            currentBullet += " " + sentence;
          } else {
            bulletPoints.push(currentBullet);
            currentBullet = sentence;
          }
        }
      });
      
      // Add the last bullet if not empty
      if (currentBullet) {
        bulletPoints.push(currentBullet);
      }
      
      // Format each bullet point
      return bulletPoints.map((bullet, index) => {
        // Clean up the bullet
        let cleanedBullet = bullet.trim().replace(/^[-•*\s]+/, '');
        
        // Add a period if it doesn't end with one
        if (!/[.!?]$/.test(cleanedBullet)) {
          cleanedBullet += '.';
        }
        
        // Add an action verb at the beginning if it doesn't start with one
        const firstWord = cleanedBullet.split(' ')[0].toLowerCase();
        if (!actionVerbs.some(verb => firstWord === verb.toLowerCase())) {
          const randomVerb = actionVerbs[Math.floor(Math.random() * actionVerbs.length)];
          cleanedBullet = `${randomVerb} ${cleanedBullet.charAt(0).toLowerCase()}${cleanedBullet.substring(1)}`;
        }
        
        return `• ${cleanedBullet}`;
      }).join('\n');
    } catch (err) {
      console.error("Error in basic formatting:", err);
      return "• " + text; // Always return at least a bullet point
    }
  }
  
  /**
   * Refine bullet points to ensure they're properly formatted
   * @param {string[]} bullets - Array of bullet points
   * @returns {string[]} - Array of refined bullet points
   */
  refineBulletPoints(bullets) {
    return bullets
      .filter(bullet => bullet.trim().length > 0)
      .map(bullet => {
        // Ensure the bullet starts with the bullet character and a space
        let refined = bullet.trim();
        if (!refined.startsWith('•')) {
          refined = '• ' + refined;
        } else if (!refined.startsWith('• ')) {
          refined = '• ' + refined.substring(1).trim();
        }
        
        // Ensure the bullet ends with punctuation
        const lastChar = refined.charAt(refined.length - 1);
        if (!['.', '!', '?', ':'].includes(lastChar)) {
          refined += '.';
        }
        
        // Ensure proper capitalization
        const textAfterBullet = refined.substring(2).trim();
        if (textAfterBullet && textAfterBullet.length > 0) {
          const firstChar = textAfterBullet.charAt(0);
          if (firstChar.toLowerCase() === firstChar) {
            refined = '• ' + firstChar.toUpperCase() + textAfterBullet.substring(1);
          }
        }
        
        return refined;
      });
  }
  
  /**
   * Check if text is already formatted with bullet points
   * @param {string} text - Text to check
   * @returns {boolean} - True if already formatted
   */
  isAlreadyFormatted(text) {
    if (!text) return false;
    
    // Check for bullet points, list items, or numbered lists
    return text.includes('•') || 
           text.includes('<li>') || 
           text.includes('<ul>') ||
           /^[-•*]\s+/m.test(text) || // Bullet points at start of lines
           /^\d+\.\s+/m.test(text);   // Numbered lists
  }
}

export default AIResumeFormatter;