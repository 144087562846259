import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from './api/axios';
import jsPDF from 'jspdf';
import AuthContext from './context/AuthProvider';
import './LetterGeneratorPage.css';
import ProgressBar from './components/ProgressBar.jsx';
import FeaturedEmployers from './FeaturedEmployers';
import SocialProof from './SocialProof';
import SalesPitchPage from './components/ResumeCritique/SalesPitchPage';
import StripeSubscriptionButton from './components/StripeSubscriptionButton.jsx';

// Environment Variable
const OPENAI_API_KEY = process.env.REACT_APP_GPT_API_KEY;

export default function LetterGeneratorPage() {
  const { applicationId, letterType } = useParams();
  const location = useLocation();
  
  // Log for debugging
  console.log(`LetterGeneratorPage: applicationId=${applicationId}, letterType=${letterType}`);
  
  const { auth } = useContext(AuthContext);
  const textareaRef = useRef(null);

  // State Variables
  const [originalResume, setOriginalResume] = useState('');
  const [jobRequirements, setJobRequirements] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [applicantName, setApplicantName] = useState('');

  const [generatedLetter, setGeneratedLetter] = useState('');
  const [editedLetter, setEditedLetter] = useState('');
  
  // Track the current letter type to detect changes
  const [currentLetterType, setCurrentLetterType] = useState(letterType);
  const [currentPathKey, setCurrentPathKey] = useState(location.pathname); // Track current path

  const [loading, setLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  // Sales pitch modal state
  const [showSalesPitchModal, setShowSalesPitchModal] = useState(false);
  const [averageScore, setAverageScore] = useState(75); // Default value
  const [missingElements, setMissingElements] = useState("Key skills and achievements");

  const pageTitle = letterType === 'cover-letter' ? 'Cover Letter' : 'Hiring Manager Letter';
  const downloadFileNameBase = letterType === 'cover-letter' ? 'Cover_Letter' : 'Hiring_Manager_Letter';
  
  useEffect(() => { 
    axios.post('/log-activity', {  sessionId: auth.sessionId,event_type:"Page Landing",current_page:'LetterGeneratorPage' + downloadFileNameBase,object_type:'Page',specific_object:'Landed on LetterGeneratorPage page' + downloadFileNameBase}, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true // This sends the cookie with the request
    });
  }, []);

  // Check path change to force refresh when letter type changes
  useEffect(() => {
    if (currentPathKey !== location.pathname) {
      console.log("Path changed from", currentPathKey, "to", location.pathname);
      
      // Force complete reset of all letter-related state
      setGeneratedLetter('');
      setEditedLetter('');
      setHasFetchedData(false);
      setCurrentPathKey(location.pathname);
      
      // Force page refresh if needed
      if (location.pathname.includes('cover-letter') && editedLetter.includes("Dear Hiring Manager")) {
        window.location.reload();
      } else if (location.pathname.includes('hiring-manager-letter') && !editedLetter.includes("Dear Hiring Manager")) {
        window.location.reload();
      }
    }
  }, [location.pathname, currentPathKey, editedLetter]);
  
  // Detect changes in letter type and reset content
  useEffect(() => {
    if (currentLetterType !== letterType) {
      console.log(`Letter type changed from ${currentLetterType} to ${letterType}`);
      
      // Reset content when letter type changes
      setGeneratedLetter('');
      setEditedLetter('');
      setCurrentLetterType(letterType);
      
      // Reset the API generation flag to trigger new content
      setIsGenerating(false);
      if (hasFetchedData) {
        console.log("Triggering new letter generation for", letterType);
        generateLetterWithAI();
      }
    }
  }, [letterType, currentLetterType, hasFetchedData]);

  // Handle window resize for responsive view
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle switching letter types with forced reload
  const handleSwitchLetterType = (newLetterType) => {
    // Force a complete page reload by directly changing location
    window.location.replace(`/generate-letter/${applicationId}/${newLetterType}`);
  };

  // Fetch Resume and Job Data
  useEffect(() => {
    const fetchData = async () => {
      if (!applicationId || !auth.sessionId) {
        setError('Missing application ID or session information.');
        setLoading(false);
        return;
      }
      setLoading(true);
      setError('');
      try {
        console.log('Fetching data for application ID:', applicationId);
        const response = await axios.get(`/critique/${applicationId}?sessionId=${auth.sessionId}`);

        if (!response.data.success) {
          throw new Error(response.data.message || 'Failed to fetch data.');
        }

        const {
          original_resume_text,
          job_requirements,
          job_title,
          company_name,
          applicant_name
        } = response.data;

        if (!original_resume_text) {
          throw new Error('Original resume text not found in the response.');
        }

        setOriginalResume(original_resume_text);
        setJobRequirements(job_requirements || '');
        setJobTitle(job_title || 'the position');
        setCompanyName(company_name || 'the company');
        setApplicantName(applicant_name || 'Applicant');

        console.log('Data fetched successfully.');
        setHasFetchedData(true);

      } catch (err) {
        console.error('Error fetching data:', err);
        setError(`Failed to load data: ${err.message}`);
        setHasFetchedData(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [applicationId, auth.sessionId, location.pathname]); // Include location.pathname as dependency

  // Generate Letter with AI
  const generateLetterWithAI = async () => {
    if (!originalResume || !jobTitle || !letterType || !OPENAI_API_KEY) {
      console.log("Missing data for letter generation:", { 
        hasResume: !!originalResume, 
        hasJobTitle: !!jobTitle, 
        letterType,
        hasApiKey: !!OPENAI_API_KEY 
      });
      setError('Missing required data or API key to generate letter.');
      return;
    }

    setIsGenerating(true);
    setError('');
    
    // Clear any existing content
    setGeneratedLetter('');
    setEditedLetter('');
    
    console.log(`Starting generation for ${letterType}...`);

    let promptContent = '';
    if (letterType === 'cover-letter') {
      console.log("Generating cover letter");
      promptContent = `
Generate a professional cover letter for ${applicantName} applying for the ${jobTitle} position at ${companyName}.
Use the candidate's experience from the provided resume to highlight suitability for the role described in the job requirements.
Structure the letter with a clear introduction, body paragraphs connecting experience to the job requirements, and a professional closing.
Address it generically (e.g., "Dear Hiring Manager,") unless a specific name is commonly included in job details (it usually isn't).
Ensure the tone is professional and enthusiastic. Keep it concise, ideally under one page.

**Job Requirements:**
${jobRequirements || 'Not specified'}

**Candidate's Resume:**
${originalResume}

Return ONLY the generated cover letter text, with appropriate paragraph breaks. Do not include any explanations, titles like "Cover Letter:", or introductions like "Here is the cover letter:".
`;
    } else if (letterType === 'hiring-manager-letter') {
      console.log("Generating hiring manager letter");
      promptContent = `
Generate a concise and professional outreach letter from ${applicantName} to a hiring manager at ${companyName} regarding the ${jobTitle} position.
This is NOT a formal cover letter, but a direct expression of interest.
Briefly highlight 1-2 key qualifications or experiences from the candidate's resume that strongly align with the job requirements.
Express strong enthusiasm for the role and the company.
Keep the letter brief, targeted, and highly professional. Ask for a brief chat or next steps.

**Job Requirements:**
${jobRequirements || 'Not specified'}

**Candidate's Resume:**
${originalResume}

Return ONLY the generated letter text, with appropriate paragraph breaks. Do not include any explanations, titles like "Hiring Manager Letter:", or introductions like "Here is the letter:".
`;
    } else {
      console.warn(`Invalid letter type: ${letterType}`);
      setError(`Invalid letter type: ${letterType}`);
      setIsGenerating(false);
      return;
    }

    try {
      console.log(`Generating ${letterType} with OpenAI...`);
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: `You are an expert at writing professional ${letterType === 'cover-letter' ? 'cover letters' : 'outreach letters to hiring managers'}.`
            },
            {
              role: "user",
              content: promptContent
            }
          ],
          temperature: 0.5,
          max_tokens: 1000
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_API_KEY}`
          }
        }
      );

      const generatedText = response.data.choices[0].message.content.trim();
      console.log(`Generated ${letterType} successfully. Sample:`, generatedText.substring(0, 50) + "...");
      
      setGeneratedLetter(generatedText);
      setEditedLetter(generatedText);

    } catch (err) {
      console.error(`Error generating ${letterType}:`, err.response?.data || err.message);
      setError(`Failed to generate ${letterType}. ${err.response?.data?.error?.message || err.message}`);
    } finally {
      setIsGenerating(false);
    }
  };

  // Trigger Generation After Data Fetch
  useEffect(() => {
    if (hasFetchedData && !isGenerating && !generatedLetter) {
      console.log(`Ready to generate letter. Type: ${letterType}, Data fetched: ${hasFetchedData}, Is generating: ${isGenerating}`);
      generateLetterWithAI();
    }
  }, [hasFetchedData, isGenerating, generatedLetter, letterType]); // Add letterType as dependency

  // Event Handlers
  const handleEdit = (event) => {
    setEditedLetter(event.target.value);
  };

  const handleRegenerate = () => {
    if (!isGenerating && hasFetchedData) {
      generateLetterWithAI();
    }
  };

  // Function to check subscription status
  async function checkSubscriptionStatus() {
    try {
      const response = await axios.post('/get-subscription', {
        sessionId: auth.sessionId
      });
      
      console.log("Subscription check response:", response.data);
      
      // Check if subscription exists and is active (status 1)
      return response.data && response.data.status === 1;
    } catch (err) {
      console.error("Error checking subscription status:", err);
      return false; // Default to no subscription on error
    }
  }

  const handleDownloadTXT = async () => {
    if (!editedLetter) {
      setError('No letter content to download.');
      return;
    }
    
    try {
      // First check subscription status
      const hasSubscription = await checkSubscriptionStatus();
      
      if (hasSubscription) {
        // User has subscription, proceed with download
        generateTXT();
      } else {
        // User doesn't have subscription, show sales pitch modal
        setShowSalesPitchModal(true);
      }
    } catch (err) {
      console.error("Error checking subscription status:", err);
      setError(`Error: ${err.message}`);
    }
  };

  const generateTXT = () => {
    setError('');
    try {
      const blob = new Blob([editedLetter], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${downloadFileNameBase}_${applicantName.replace(/\s+/g, '_') || 'Applicant'}.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      
      setError('TXT Downloaded Successfully!');
      setTimeout(() => setError(''), 3000);
    } catch (err) {
      console.error("Error downloading TXT:", err);
      setError("Failed to download TXT file.");
    }
  };

  const handleDownloadPDF = async () => {
    if (!editedLetter) {
      setError('No letter content to download.');
      return;
    }
    
    try {
      // First check subscription status
      const hasSubscription = await checkSubscriptionStatus();
      
      if (hasSubscription) {
        // User has subscription, proceed with download
        generatePDF();
      } else {
        // User doesn't have subscription, show sales pitch modal
        setShowSalesPitchModal(true);
      }
    } catch (err) {
      console.error("Error checking subscription status:", err);
      setError(`Error: ${err.message}`);
    }
  };

  const generatePDF = () => {
    setError('');
    try {
      const doc = new jsPDF();
      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;
      const margin = 20;
      const usableWidth = pageWidth - 2 * margin;
      let y = margin;

      // Set font
      doc.setFont('times', 'normal');
      doc.setFontSize(12);

      // Split text into lines respecting the usable width
      const lines = doc.splitTextToSize(editedLetter, usableWidth);

      lines.forEach(line => {
        if (y + 10 > pageHeight - margin) {
          doc.addPage();
          y = margin;
        }
        doc.text(line, margin, y);
        y += 6;
      });

      doc.save(`${downloadFileNameBase}_${applicantName.replace(/\s+/g, '_') || 'Applicant'}.pdf`);
      
      setError('PDF Downloaded Successfully!');
      setTimeout(() => setError(''), 3000);

    } catch (err) {
      console.error("Error downloading PDF:", err);
      setError("Failed to download PDF file.");
    }
  };


  // Render Loading State
  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
        <p>Loading applicant data...</p>
      </div>
    );
  }

  // Also show loading when generating a letter
  if (isGenerating) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
        <p>Generating {letterType === 'cover-letter' ? 'cover letter' : 'hiring manager letter'} with AI...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="letter-generator-page">
        <header className={`letter-generator-header ${isMobileView ? 'mobile-header' : 'desktop-header'}`}>
          <h2 className="letter-builder-heading">
            <span>{pageTitle} </span>
            <span className="ai-text">Generator</span>
          </h2>
          
          <div className="action-buttons">
            <button 
              onClick={handleRegenerate} 
              disabled={isGenerating || !hasFetchedData} 
              className="action-btn regenerate-btn"
            >
              <i className="fas fa-sync-alt"></i>
              {isGenerating ? 'Generating...' : 'Regenerate'}
            </button>
            
            <button 
              onClick={handleDownloadTXT} 
              disabled={!editedLetter} 
              className="action-btn download-btn"
            >
              <i className="fas fa-file-alt"></i>
              Download TXT
            </button>
            
            <button 
              onClick={handleDownloadPDF} 
              disabled={!editedLetter} 
              className="action-btn download-btn"
            >
              <i className="fas fa-file-pdf"></i>
              Download PDF
            </button>

            {/* Alternate letter type button - force complete page reload */}
            {letterType === 'hiring-manager-letter' ? (
              <button
                onClick={() => handleSwitchLetterType('cover-letter')}
                className="action-btn generate-letter-btn"
              >
                <i className="fas fa-envelope"></i>
                Cover Letter
              </button>
            ) : (
              <button
                onClick={() => handleSwitchLetterType('hiring-manager-letter')}
                className="action-btn generate-hm-letter-btn"
              >
                <i className="fas fa-user-tie"></i>
                Hiring Manager
              </button>
            )}
            
            {/*     <StripeSubscriptionButton/>  */}  
          </div>
        </header>

        {error && <div className={`error-message ${error.includes('Failed') ? 'error' : ''}`}>{error}</div>}

        <div className="letter-editor-container">
          <textarea
            ref={textareaRef}
            value={editedLetter}
            onChange={handleEdit}
            placeholder={hasFetchedData ? "Edit your letter here..." : "Waiting for data..."}
            className="letter-textarea"
            disabled={isGenerating || !hasFetchedData}
          />
        </div>

        <footer className="letter-generator-footer">
          <p>Review and edit the generated letter carefully before sending.</p>
        </footer>
      </div>
      
      {/* Progress Bar Component */}
      <ProgressBar
        currentStep={4}
        totalSteps={4}
        stepLabels={["Upload Resume", "Job Details", "Critique", "Generate Letter"]}
        stepPaths={[
          "/UploadResume",
          "/JobDetails",
          `/critique/${applicationId}`,
          `/critique/${auth.applicationId}`,
          `/generate-letter/${applicationId}/${letterType}`
        ]}
      />
      
      {/* Featured Employers and Social Proof Components */}
      <FeaturedEmployers />
      <SocialProof />

      {/* Sales Pitch Modal */}
      {showSalesPitchModal && (
        <SalesPitchPage 
          onClose={() => setShowSalesPitchModal(false)}
          averageScore={averageScore}
          missingElements={missingElements}
          applicationId={applicationId}
          auth={auth}
        />
      )}
    </div>
  );
}