import { createContext, useState,useContext } from "react";
import React from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    username: localStorage.getItem('username') || '',
    credits: localStorage.getItem('credits') || 0,
    loggedIn: localStorage.getItem('loggedIn') || 0,
    sessionId: localStorage.getItem('sessionId') || 0,
    email: localStorage.getItem('email') || 0,
    user_role_type: localStorage.getItem('user_role_type') || 0,
    isVerified: localStorage.getItem('isVerified') || 0,
    applicationId: localStorage.getItem('applicationId') || ''  ,
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
