// ContentOverflowDetector.jsx
import React, { useEffect } from 'react';

/**
 * Detects if resume content overflows a single page and adjusts container height
 * to accommodate the overflow by adding additional page space.
 */
export function detectAndHandleContentOverflow(grapesEditorRef) {
  try {
    // Get the editor instance
    const editor = grapesEditorRef.current;
    if (!editor) {
      console.warn("Editor not initialized, cannot check for content overflow");
      return false;
    }
    
    // Get the iframe document
    const iframe = editor.Canvas.getFrameEl();
    if (!iframe) {
      console.warn("Cannot find editor frame");
      return false;
    }
    
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    if (!iframeDocument) {
      console.warn("Cannot access iframe document");
      return false;
    }
    
    // Find the main resume container
    const resumeContainer = iframeDocument.querySelector('div[style*="max-width: 8.5in"]') || 
                           iframeDocument.querySelector('.resume-container');
    
    if (!resumeContainer) {
      console.warn("Cannot find resume container");
      return false;
    }
    
    console.log("Checking for content overflow...");
    
    // Standard US Letter page dimensions (in pixels at 96 DPI)
    const PAGE_WIDTH_PX = 8.5 * 96;  // 8.5in * 96px/in = 816px
    const PAGE_HEIGHT_PX = 11 * 96;  // 11in * 96px/in = 1056px
    const CONTAINER_PADDING = 40;    // Typical padding in pixels
    
    // Calculate usable height (accounting for margins/padding)
    const usablePageHeight = PAGE_HEIGHT_PX - CONTAINER_PADDING;
    
    // ENHANCED DETECTION: Multiple approaches to check for overflow
    
    // 1. Check scrollHeight vs offsetHeight
    const contentHeight = resumeContainer.scrollHeight;
    const visibleHeight = resumeContainer.offsetHeight;
    const scrollOverflow = contentHeight > visibleHeight;
    
    // 2. Check if any elements extend beyond the container bounds
    const allElements = resumeContainer.querySelectorAll('*');
    let elementsOverflow = false;
    const containerBottom = resumeContainer.getBoundingClientRect().bottom;
    
    for (const element of allElements) {
      const elementBottom = element.getBoundingClientRect().bottom;
      if (elementBottom > containerBottom + 10) { // Adding a small threshold
        elementsOverflow = true;
        console.log(`Element overflow detected: ${element.tagName} extends beyond container by ${elementBottom - containerBottom}px`);
        break;
      }
    }
    
    // 3. Check the last element to see if it's fully visible
    const lastElement = allElements[allElements.length - 1];
    let lastElementOverflow = false;
    
    if (lastElement) {
      const lastElementBottom = lastElement.getBoundingClientRect().bottom;
      lastElementOverflow = lastElementBottom > containerBottom;
      console.log(`Last element (${lastElement.tagName}) position: ${lastElementBottom}, container bottom: ${containerBottom}`);
    }
    
    // 4. Compare content height against fixed letter size
    const fixedSizeOverflow = contentHeight > usablePageHeight;
    
    console.log(`Overflow detection results:
      - Content height: ${contentHeight}px vs Page height: ${usablePageHeight}px
      - Scroll detection: ${scrollOverflow ? 'Overflow' : 'No overflow'}
      - Elements detection: ${elementsOverflow ? 'Overflow' : 'No overflow'}
      - Last element detection: ${lastElementOverflow ? 'Overflow' : 'No overflow'}
      - Fixed size comparison: ${fixedSizeOverflow ? 'Overflow' : 'No overflow'}`);
    
    // Determine if overflow exists using all detection methods
    const hasOverflow = fixedSizeOverflow || scrollOverflow || elementsOverflow || lastElementOverflow;
    
    // Check if content exceeds single page
    if (hasOverflow) {
      // Calculate how many additional pages are needed
      const additionalPagesNeeded = Math.ceil((contentHeight - usablePageHeight) / PAGE_HEIGHT_PX);
      console.log(`Content overflows by ${contentHeight - usablePageHeight}px, adding ${additionalPagesNeeded} page(s)`);
      
      // Set new container height with room for additional pages
      const newHeight = PAGE_HEIGHT_PX + (additionalPagesNeeded * PAGE_HEIGHT_PX);
      resumeContainer.style.height = `${newHeight}px`;
      resumeContainer.style.minHeight = `${newHeight}px`;
      
      // Remove any existing page break indicators first
      const existingIndicators = iframeDocument.querySelectorAll('.page-break-indicator');
      existingIndicators.forEach(indicator => indicator.remove());
      
      // Add page break style if it doesn't exist
      let pageBreakStyle = iframeDocument.querySelector('style[data-page-breaks]');
      if (!pageBreakStyle) {
        pageBreakStyle = iframeDocument.createElement('style');
        pageBreakStyle.setAttribute('data-page-breaks', 'true');
        iframeDocument.head.appendChild(pageBreakStyle);
      }
      
      // Enhanced CSS for better page break visualization and consistency
      let pageBreakCss = `
        @media screen {
          .resume-container {
            position: relative !important;
            min-height: ${newHeight}px !important;
            height: ${newHeight}px !important;
            overflow: visible !important;
            padding-bottom: 40px !important;
            background-color: white !important;
          }
          
          .page-break-indicator {
            position: absolute !important;
            left: 0 !important;
            right: 0 !important;
            height: 2px !important;
            background: repeating-linear-gradient(to right, #cc0000, #cc0000 10px, transparent 10px, transparent 20px) !important;
            z-index: 1000 !important;
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
            pointer-events: none !important;
          }
          
          .page-break-label {
            position: absolute !important;
            right: 10px !important;
            top: -20px !important;
            background: #ffffff !important;
            color: #cc0000 !important;
            font-size: 12px !important;
            font-weight: bold !important;
            padding: 2px 8px !important;
            border: 1px solid #cc0000 !important;
            border-radius: 4px !important;
            z-index: 1001 !important;
            pointer-events: none !important;
            box-shadow: 0 1px 3px rgba(0,0,0,0.1) !important;
          }
          
          /* Add a page number in the bottom right of each page */
          .page-number {
            position: absolute !important;
            right: 20px !important;
            bottom: 10px !important;
            font-size: 11px !important;
            color: #666 !important;
            z-index: 1001 !important;
          }
          
          /* Create visual boundaries for each page */
          .page-container {
            position: relative !important;
            height: ${PAGE_HEIGHT_PX}px !important;
            border-bottom: 1px solid #eee !important;
            margin-bottom: 0 !important;
            box-sizing: border-box !important;
          }
          
          .page-container:last-child {
            border-bottom: none !important;
          }
        }
        
        @media print {
          .resume-container {
            page-break-inside: auto !important;
          }
          
          h1, h2, h3, h4 {
            page-break-after: avoid !important;
          }
          
          li, img, table {
            page-break-inside: avoid !important;
          }
          
          .page-break-indicator, .page-break-label, .page-number {
            display: none !important;
          }
          
          @page {
            size: letter !important;
            margin: 0.5in !important;
          }
        }
      `;
      
      pageBreakStyle.textContent = pageBreakCss;
      
      // Create page containers for each page
      // First, wrap existing content in a first page container
      const firstPageContainer = iframeDocument.createElement('div');
      firstPageContainer.className = 'page-container';
      firstPageContainer.setAttribute('data-page', '1');
      
      // Move all child elements into the first page container
      while (resumeContainer.firstChild) {
        firstPageContainer.appendChild(resumeContainer.firstChild);
      }
      
      // Add the first page container back to the resume container
      resumeContainer.appendChild(firstPageContainer);
      
      // Add first page number
      const firstPageNumber = iframeDocument.createElement('div');
      firstPageNumber.className = 'page-number';
      firstPageNumber.textContent = 'Page 1';
      firstPageContainer.appendChild(firstPageNumber);
      
      // Create indicators and containers for additional pages
      for (let i = 1; i <= additionalPagesNeeded; i++) {
        // Create page break indicator at the page boundary
        const pageBreakIndicator = iframeDocument.createElement('div');
        pageBreakIndicator.className = 'page-break-indicator';
        pageBreakIndicator.style.top = `${PAGE_HEIGHT_PX * i}px`;
        
        // Add page label
        const pageLabel = iframeDocument.createElement('div');
        pageLabel.className = 'page-break-label';
        pageLabel.textContent = `Page ${i + 1}`;
        pageBreakIndicator.appendChild(pageLabel);
        
        // Create an empty container for this page's content
        const pageContainer = iframeDocument.createElement('div');
        pageContainer.className = 'page-container';
        pageContainer.setAttribute('data-page', `${i + 1}`);
        pageContainer.style.position = 'absolute';
        pageContainer.style.top = `${PAGE_HEIGHT_PX * i}px`;
        pageContainer.style.left = '0';
        pageContainer.style.right = '0';
        pageContainer.style.height = `${PAGE_HEIGHT_PX}px`;
        pageContainer.style.backgroundColor = 'white';
        pageContainer.style.zIndex = '1';
        pageContainer.style.padding = '30px';
        pageContainer.style.boxSizing = 'border-box';
        
        // Add page number
        const pageNumber = iframeDocument.createElement('div');
        pageNumber.className = 'page-number';
        pageNumber.textContent = `Page ${i + 1}`;
        pageContainer.appendChild(pageNumber);
        
        // Add page container and indicator to resume container
        resumeContainer.appendChild(pageBreakIndicator);
        resumeContainer.appendChild(pageContainer);
      }
      
      // Set a flag for multiple pages (can be used for print settings)
      resumeContainer.setAttribute('data-multi-page', 'true');
      resumeContainer.setAttribute('data-pages', additionalPagesNeeded + 1);
      
      // Add class to make overflow visible in editor
      resumeContainer.classList.add('multi-page-resume');
      
      // Attempt to redistribute content across pages
      redistributeContent(iframeDocument, resumeContainer, PAGE_HEIGHT_PX);
      
      console.log(`Adjusted container height to ${newHeight}px for ${additionalPagesNeeded + 1} pages`);
      return true;
    } else {
      console.log("Content fits within a single page");
      
      // Reset to single page height if previously expanded
      if (resumeContainer.getAttribute('data-multi-page') === 'true') {
        resumeContainer.style.height = `${PAGE_HEIGHT_PX}px`;
        resumeContainer.style.minHeight = `${PAGE_HEIGHT_PX}px`;
        resumeContainer.removeAttribute('data-multi-page');
        resumeContainer.removeAttribute('data-pages');
        resumeContainer.classList.remove('multi-page-resume');
        
        // Remove any page break indicators
        const existingPageBreakStyles = iframeDocument.querySelectorAll('style[data-page-breaks]');
        existingPageBreakStyles.forEach(style => style.remove());
        
        // Remove any page containers and indicators
        const pageContainers = iframeDocument.querySelectorAll('.page-container');
        const pageBreakIndicators = iframeDocument.querySelectorAll('.page-break-indicator');
        
        // If we have page containers, extract their content back to the main container
        if (pageContainers.length > 0) {
          // Collect all content
          let allContent = [];
          pageContainers.forEach(container => {
            // Skip page numbers
            const contentElements = Array.from(container.childNodes).filter(
              node => !node.classList || !node.classList.contains('page-number')
            );
            allContent = allContent.concat(contentElements);
          });
          
          // Clear the container
          resumeContainer.innerHTML = '';
          
          // Add content back
          allContent.forEach(node => {
            resumeContainer.appendChild(node);
          });
        }
        
        // Remove any remaining indicators
        pageBreakIndicators.forEach(indicator => indicator.remove());
        
        console.log("Reset to single page height");
      }
      
      return false;
    }
  } catch (err) {
    console.error("Error in content overflow detection:", err);
    return false;
  }
}

/**
 * Tries to redistribute content across page containers for better reading experience
 */
function redistributeContent(doc, containerElement, pageHeight) {
  try {
    // Get all page containers
    const pageContainers = containerElement.querySelectorAll('.page-container');
    if (pageContainers.length <= 1) return; // No redistribution needed
    
    // Collect all content elements from first page (excluding page number)
    const firstPageContent = Array.from(pageContainers[0].childNodes).filter(
      node => !node.classList || !node.classList.contains('page-number')
    );
    
    // If no content to redistribute, exit
    if (firstPageContent.length === 0) return;
    
    // Get section headers - these are potential break points
    const sectionHeaders = [];
    firstPageContent.forEach(node => {
      if (node.tagName && ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(node.tagName)) {
        sectionHeaders.push(node);
      }
    });
    
    // Look for sections that would overflow to next page
    for (const header of sectionHeaders) {
      const headerPosition = header.getBoundingClientRect().top;
      const containerTop = pageContainers[0].getBoundingClientRect().top;
      const relativePosition = headerPosition - containerTop;
      
      // If this header is near the page bottom, move it and following content to next page
      if (relativePosition > pageHeight - 150) { // 150px buffer from bottom
        console.log(`Header "${header.textContent}" is near page bottom, moving to next page`);
        
        // Find all elements after this header
        let currentElement = header;
        const elementsToMove = [header];
        
        while (currentElement.nextSibling) {
          elementsToMove.push(currentElement.nextSibling);
          currentElement = currentElement.nextSibling;
        }
        
        // Move elements to the second page
        if (pageContainers.length > 1) {
          elementsToMove.forEach(element => {
            pageContainers[1].insertBefore(element, pageContainers[1].querySelector('.page-number'));
          });
          
          console.log(`Moved ${elementsToMove.length} elements to next page`);
          break; // Only handle one header at a time
        }
      }
    }
  } catch (err) {
    console.error("Error in content redistribution:", err);
  }
}

/**
 * Sets up a mutation observer to detect content changes and check for overflow
 */
export function setupContentOverflowDetection(grapesEditorRef) {
  // Run multiple checks with increasing delays to ensure content is fully loaded
  const checkTimes = [100, 500, 1000, 2000, 3000];
  
  checkTimes.forEach(delay => {
    setTimeout(() => {
      if (grapesEditorRef.current) {
        detectAndHandleContentOverflow(grapesEditorRef);
      }
    }, delay);
  });
  
  // Set up mutation observer to check for content changes
  const editor = grapesEditorRef.current;
  if (!editor) return;
  
  const iframe = editor.Canvas.getFrameEl();
  if (!iframe) return;
  
  const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
  if (!iframeDocument) return;
  
  // Find the main resume container
  const resumeContainer = iframeDocument.querySelector('div[style*="max-width: 8.5in"]') || 
                         iframeDocument.querySelector('.resume-container');
  
  if (!resumeContainer) return;
  
  // Clean up any existing observer
  if (editor.ContentOverflowObserver) {
    editor.ContentOverflowObserver.disconnect();
  }
  
  // Create mutation observer to detect content changes
  const observer = new MutationObserver(debounce(() => {
    detectAndHandleContentOverflow(grapesEditorRef);
  }, 300));
  
  // Start observing the container for content changes
  observer.observe(resumeContainer, { 
    childList: true, 
    subtree: true,
    characterData: true,
    attributes: true 
  });
  
  // Also observe the body element to catch structural changes
  observer.observe(iframeDocument.body, {
    childList: true,
    subtree: false
  });
  
  // Set up scroll event listener to check for overflow during scrolling
  iframe.contentWindow.addEventListener('scroll', debounce(() => {
    detectAndHandleContentOverflow(grapesEditorRef);
  }, 300));
  
  // Also check whenever window is resized
  window.addEventListener('resize', debounce(() => {
    detectAndHandleContentOverflow(grapesEditorRef);
  }, 300));
  
  // Store observer reference for cleanup
  editor.ContentOverflowObserver = observer;
  
  console.log("Enhanced content overflow detection set up");
  
  // Return the cleanup function
  return () => {
    if (editor.ContentOverflowObserver) {
      editor.ContentOverflowObserver.disconnect();
    }
    iframe.contentWindow.removeEventListener('scroll', detectAndHandleContentOverflow);
    window.removeEventListener('resize', detectAndHandleContentOverflow);
  };
}

/**
 * Utility function to debounce function calls
 */
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

/**
 * Enhances PDF export with multi-page support
 */
export function enhancePDFExportWithPageHandling(handleDownloadPDF, grapesEditorRef, setLoading, setError) {
  return async function enhancedPdfExport() {
    try {
      setLoading(true);
      setError("Preparing PDF for export...");
      
      // Run overflow detection one more time before export
      await new Promise(resolve => {
        setTimeout(() => {
          detectAndHandleContentOverflow(grapesEditorRef);
          resolve();
        }, 500);
      });
      
      // Proceed with original export function
      await handleDownloadPDF();
      
    } catch (err) {
      console.error("Enhanced PDF export error:", err);
      setError(`PDF export failed: ${err.message}`);
      setLoading(false);
    }
  };
}

/**
 * Hook to handle page overflow detection in a React component
 */
export function usePageOverflowDetection(grapesEditorRef) {
  useEffect(() => {
    if (grapesEditorRef.current) {
      // Set up initial detection with delay to ensure content is rendered
      const initialTimer = setTimeout(() => {
        detectAndHandleContentOverflow(grapesEditorRef);
      }, 1000);
      
      // Set up observer for content changes
      const cleanup = setupContentOverflowDetection(grapesEditorRef);
      
      // Cleanup function
      return () => {
        clearTimeout(initialTimer);
        if (cleanup) cleanup();
      };
    }
  }, [grapesEditorRef.current]);
  
  // Return the detection function so it can be called manually
  return () => detectAndHandleContentOverflow(grapesEditorRef);
}

/**
 * Ensures proper container height and page break settings in HTML documents
 */
export function ensureProperContainerHeight(doc) {
  // Set initial dimensions based on US Letter
  const letterWidth = 8.5;
  const letterHeight = 11;
  
  // Find or create the main container
  let container = doc.querySelector('div[style*="max-width: 8.5in"]') || 
                 doc.querySelector('.resume-container');
  
  if (!container) {
    // If no container exists, we might be working with a different template structure
    // In this case, we'll wrap the body contents in a new container
    container = doc.createElement('div');
    container.className = 'resume-container';
    
    // Move all body children to the new container
    while (doc.body.firstChild) {
      container.appendChild(doc.body.firstChild);
    }
    
    doc.body.appendChild(container);
  }
  
  // Ensure container has proper sizing properties
  container.style.maxWidth = `${letterWidth}in`;
  container.style.width = `${letterWidth}in`;
  container.style.minHeight = `${letterHeight}in`;
  container.style.position = 'relative';
  container.style.backgroundColor = 'white';
  container.style.margin = '0 auto';
  container.style.padding = '0.5in';
  container.style.boxSizing = 'border-box';
  
  // Add styles for page breaks
  const styleElement = doc.createElement('style');
  styleElement.textContent = `
    @media print {
      .resume-container {
        page-break-inside: auto;
      }
      
      h1, h2, h3, h4 {
        page-break-after: avoid;
      }
      
      li, img, table {
        page-break-inside: avoid;
      }
      
      @page {
        size: letter;
        margin: 0.5in;
      }
    }
  `;
  
  doc.head.appendChild(styleElement);
  
  return doc;
}