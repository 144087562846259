import React, { useState, useEffect } from 'react';
import { useAuth } from './context/AuthProvider';
import { AuthProvider } from './context/AuthProvider.js';
import axios from './api/axios';
import { isEmail } from 'validator';
import { Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './AccountPage.css';
import { DataGrid } from '@mui/x-data-grid';
import useWindowDimensions from './functions/useWindowDimensions.jsx'
import {MyAlertSubscription,MyAlertSubscriptionStripe} from './components/MyAlert.jsx';
import AuthenticatedComponent from './components/AuthenticatedComponent';

const AccountPage = () => {
  const { auth, setAuth } = useAuth();
  const [newEmail, setNewEmail] = useState('');
  const [commission, setCommission] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isVerfiyButtonDisabled, setIsVerfiyButtonDisabled] = useState(false);
  const [isVerified, setIsVerified] = useState(0);
  const [verificationCode, setVerificationCode] = useState('');
  const [spacing, setSpacing] = React.useState(2);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState([]);
  const { height, width } = useWindowDimensions();

  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [subscriptionService, setSubscriptionService] = useState(null);

  const [rows, setRows] = useState([]); 

  const [serviceDoc, setServiceDoc] = useState(''); 
  const [docId, setDocId] = useState(); 
  const [documentOpen, setDocumentOpen] = useState(false);
  const handleDocumentOpenModal = () => setDocumentOpen(true);
  const handleDocumentCloseModal = () => setDocumentOpen(false);

  
  useEffect(() => {
    ////console.log("user_role_type");
    //console.log(auth.user_role_type);
    if(auth.user_role_type == "BASIC PLAN" || auth.user_role_type == "ADVANTAGE PLAN" || auth.user_role_type == "ELITE PLAN"  ){
      setIsSubscribed(true);
    }

    // Get subscription details to determine the service type
    if (auth.sessionId) {
      axios.post('/get-subscription', {
        sessionId: auth.sessionId
      })
      .then(response => {
        if (response.data && response.data.status === 1) {
          setSubscriptionInfo(response.data);
          setSubscriptionService(response.data.subscription_service);
          //console.log("Subscription service type:", response.data.subscription_service);
        }
      })
      .catch(err => {
        console.error("Error checking subscription status:", err);
      });
    }
  }, []);

  /* useEffect(() => {
    //console.log("rows");
    //console.log(rows);
    //console.log("pre-get-user-documents");
    
     axios.post('/get-user-documents', {
      sessionId: auth.sessionId, 
      }, 
      {
        headers: {'Content-Type': 'application/x-www-form-urlencoded',},
        withCredentials: true // This sends the cookie with the request
      })
      .then(response => {
        //console.log("inside-get-user-documents");
        //console.log(response.data);

        setRows([]);
        setRows(
          Array.isArray(response.data) && response.data.length
            ? response.data.map((item, index) => {
                if (!item.hasOwnProperty('service') || !item.hasOwnProperty('input') || !item.hasOwnProperty('output')) {
                  throw new Error('Unexpected data format. Each item must have a "service", "input", and "output" property.');
                }
                return {
                  id: index,
                  service: item.service || '',
                  input: item.input || '',
                  output: item.output || '',
                  docId: item.docId || '',
                  html: item.html || ''
                };
              })
            : []
        );
        //console.log("after-get-user-documents");
        //console.log(rows);
      })
      .catch(error => {
        //console.log("error-get-user-documents");
        //console.log(error);
      });
      //console.log("rows");
    //console.log(rows);
    }, [documentOpen]); // The empty array ensures that the effect only runs on page load
 */
  useEffect(() => {
    
    axios.post('/isVerified', {
      sessionId: auth.sessionId, 

      }, 
      {
        headers: {'Content-Type': 'application/x-www-form-urlencoded',},
        withCredentials: true // This sends the cookie with the request
      })
      .then(response => {
        setIsVerified(response.data);
      // Do something with the response data
      ////console.log(response.data);
      })
      .catch(error => {
      //console.error(error);
      });

      ////console.log("isverified: "+ isVerified);

    axios.post('/get-commission', {
    username: auth.username 
    }, 
    {
      headers: {'Content-Type': 'application/x-www-form-urlencoded',},
      withCredentials: true // This sends the cookie with the request
    })
    .then(response => {
        setCommission(response.data.commission);
    // Do something with the response data
    ////console.log(response.data);
    })
    .catch(error => {
    //console.error(error);
    });
    }, []); // The empty array ensures that the effect only runs on page load


    useEffect(() => { 
      axios.post('/log-activity', {  sessionId: auth.sessionId,event_type:"Page Landing",current_page:'AccountPage',object_type:'Page',specific_object:'Landed on AccountPage page'}, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      });
    }, []);

    const handleVerifyEmail = (event) => {
      event.preventDefault();
      setIsVerfiyButtonDisabled(true); // enable the button


      // Use a regular expression to check for unwanted characters
    const unwantedCharacters = /[;'\"\s-]/g;
    if(unwantedCharacters.test(verificationCode)) {
        // Stop the input
        event.preventDefault();
        alert("Characters ';' '\"' '\'' '-' are not allowed.");
        setIsButtonDisabled(false); // enable the button
        return;
    }

      axios.post('/verify-email', {
        verificationCode: verificationCode,
        sessionId: auth.sessionId
      }, 
      {
        headers: {'Content-Type': 'application/x-www-form-urlencoded',},
        withCredentials: true // This sends the cookie with the request
      })
        .then((response) => {
          // Update the user object in your frontend state with the new email
          localStorage.setItem('isVerified',1)
          setAuth({ ...auth, isVerified:  1  });
          
          setIsVerified(1);
          if(response.data.reward != 0 && response.data.reward != null && response.data.reward != undefined ){
            alert(`Email successfully Verified. Congrats ${response.data.reward} credits have been added to your account!`);
          }else{
            alert("Email successfully Verified.");
          }
          window.location.replace("/");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            alert("Inccorrect verification code");
          } 
          //console.error(error);
        });
        setIsVerfiyButtonDisabled(false); // enable the button


    }


    function restoreSQLInjection(text) {
      text = String(text);
      return text.replace(/&apos;/g, "'")
      .replace(/&quot;/g, '"')
      .replace(/&semi;/g, ";")
      .replace(/&dash;/g, "-")
      .replace(/&plus;/g, "+");
    }

  const handleChangeEmail = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true); // enable the button

    // Use a regular expression to check for unwanted characters
    const unwantedCharacters = /[;'\"\s-]/g;
    if(unwantedCharacters.test(newEmail)) {
        // Stop the input
        event.preventDefault();
        alert("Characters ';' '\"' '\'' '-' are not allowed.");
        setIsButtonDisabled(false); // enable the button
        return;
    }

    if (!isEmail(newEmail)) {
        // Show error message: "Please enter a valid email"
        alert("Please enter a valid email.");
        setIsButtonDisabled(false); // enable the button
        return;
      }
      
    
    axios.post('/change-email', {
      newEmail: newEmail,
      sessionId: auth.sessionId
    }, 
    {
      headers: {'Content-Type': 'application/x-www-form-urlencoded',},
      withCredentials: true // This sends the cookie with the request
    })
      .then(() => {
        // Update the user object in your frontend state with the new email
        localStorage.setItem('email',newEmail)
        setAuth({ ...auth, email: newEmail });
        alert("Email successfully updated.");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          alert("Conflict, this email already exists");
        } 
        //console.error(error);
      });
      setIsButtonDisabled(false); // enable the button
  };



  const [id,setId] = useState(0);
  const [name,setName] = useState(0);



  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'service',
      headerName: 'Document Type',
      width: 200,
      editable: true,
    },
    {
      field: 'input',
      headerName: 'Original document',
      width: 150,
      editable: true,
    },
    {
      field: 'output',
      headerName: 'Optimized Resume',
      width: 150,
      editable: false,
    }
  ];


  const handleSelectionChange = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
  };


  const HandleEditDocument = () => {
    if(selectedRows.length > 1){
      alert("Only select 1 document to edit at a time.");
    } else if(selectedRows.length < 1){
      alert("Select 1 document to edit.");
    } else if(selectedRows.length == 1) {
    //console.log(selectedRows);
    if(selectedRows[0].html == null || selectedRows[0].html == ''){
      setSelectedDocument(selectedRows[0].output);
    }else{
      setSelectedDocument(selectedRows[0].html);
    }
    //console.log("selectedRows[0].service");
    //console.log(selectedRows[0].service);
    setServiceDoc(selectedRows[0].service);
    //console.log("serviceDoc");
    //console.log(serviceDoc);
    setDocId(selectedRows[0].docId)
    setDocumentOpen(true);
    }
  };


  const [state, setState] = useState({
    fileType: "text",
    fileDownloadUrl: null,
    status: ""
  });
  const defaultFileType = "text";
  const fileNames = {
    json: "document.json",
    csv: "document.csv",
    text: "document.txt"
  };
  const downloadOptimized = () => {
    selectedRows.forEach(selectedRow => {
      let restoredDoc = null;
      if(selectedRow.html == null || selectedRow.html == ''){
         restoredDoc = restoreSQLInjection(selectedRow.output);
      }else{
         restoredDoc = restoreSQLInjection(selectedRow.html);
      }
      
      const blob = new Blob([restoredDoc]);
      const fileDownloadUrl = URL.createObjectURL(blob);
  
      const link = document.createElement("a");
      link.href = fileDownloadUrl;
      link.download = `${selectedRow.service}.txt`;
      link.click();
  
      URL.revokeObjectURL(fileDownloadUrl);
    });
  };

  const downloadOriginal= () => {
    selectedRows.forEach(selectedRow => {
      let restoredDoc = null;
      if(selectedRow.html == null || selectedRow.html == ''){
         restoredDoc = restoreSQLInjection(selectedRow.output);
      }else{
         restoredDoc = restoreSQLInjection(selectedRow.html);
      }
      //const restoredDoc = restoreSQLInjection(selectedRow.html);
      const blob = new Blob([restoredDoc]);
      const fileDownloadUrl = URL.createObjectURL(blob);
  
      const link = document.createElement("a");
      link.href = fileDownloadUrl;
      link.download = `${selectedRow.service}.txt`;
      link.click();
  
      URL.revokeObjectURL(fileDownloadUrl);
    });
  };
  

  return (
   
    // <Container>
    <div style={{ textAlign: 'center',
    padding: '20px',
    marginLeft: width > 700 ? '20%' : '0',
    marginRight: width > 700 ? '20%' : '0' }}>
    
    <div>
      <AuthenticatedComponent>
      </AuthenticatedComponent>
      <h1 className="mx-auto text-left">Account</h1>
      <p className="mx-auto text-left">Username: {auth.username}</p>
      <p className="mx-auto text-left">Credits: {auth.credits}</p>
      <p className="mx-auto text-left">Account type: {(auth.user_role_type == "BASIC PLAN" ) ? "Member" : auth.user_role_type}</p>
      {auth.user_role_type == 'affiliate user' ? <p className="mx-auto text-left">Commission: ${commission}</p>: <p></p>}
      <p className="mx-auto text-left">Email: {auth.email}</p>
 {/*      <div className="d-flex justify-content-left">
      <form onSubmit={handleChangeEmail}>
        <label>
          New Email:  
          <input
            type="email"
            value={newEmail}
            onChange={(event) => setNewEmail(event.target.value)}
          />
        </label>
        <p></p>
        <div className="d-flex justify-content-left">
        {!isButtonDisabled ? <button type="submit"  disabled={isButtonDisabled} className="btn btn-primary">Change Email</button> : <p>Loading...</p>}
        </div>
      </form>
      </div> */}
      <br/>
      <br/>
      {auth.isVerified != 1 ?   
      <div className="d-flex justify-content-center">     
      <form onSubmit={handleVerifyEmail}>
      <h3 className="mx-auto text-center" style={{ color: "red" }}>Your email is not verified</h3>
        <p className="mx-auto text-center" >
          Verify Email (add verification code):  
          </p>
          <div className="d-flex justify-content-center">
          <input
            type="verification code"
            value={verificationCode}
            onChange={(event) => setVerificationCode(event.target.value)}
          />
        </div> 
        <p></p>
        <div className="d-flex justify-content-center">
        {!isVerfiyButtonDisabled ? <button type="submit"  disabled={isVerfiyButtonDisabled} className="btn btn-success">Verify Email</button> : <p>Loading...</p>}
        </div>
        <br/>
        <p className="mx-auto text-center" style={{ color:'#257883' }}>
          Any Referal code rewards will be dispursed after verification  
          </p>
      </form>
      </div>
      : null}
    </div>

  {/*   <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        //onSelectionChange={handleSelectionChange}
        onSelectionModelChange={(newSelection) => {
          setSelectedRows(
            newSelection.map(index => rows[index])
          );
          setSelectedRowsIndexes(newSelection);
      }}
      selectionModel={selectedRowsIndexes}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
    <Button variant="outlined" onClick={downloadOriginal}>
      Download Original Document
    </Button>
    <Button variant="outlined" onClick={downloadOptimized}>
      Download Optimized Document
    </Button> */}
     <br/>
    <br/>
    {isSubscribed ? (
      isSubscriptionOpen ? 
        (subscriptionService === 1 ? 
          
        
          <MyAlertSubscription 
            isSubscriptionOpen={isSubscriptionOpen} 
            setIsSubscriptionOpen={setIsSubscriptionOpen} 
          />
          :
          <MyAlertSubscriptionStripe 
            isSubscriptionOpen={isSubscriptionOpen} 
            setIsSubscriptionOpen={setIsSubscriptionOpen} 
            subscriptionInfo={subscriptionInfo}
          /> 
        ) 
      : 
        <Button variant="outlined" onClick={()=> setIsSubscriptionOpen(true)}> 
          Subscription
        </Button>
    ) : null}
    </div>
    
  );
};

export default AccountPage;