import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./SocialProof.css";
import { FaStar } from "react-icons/fa";


const reviews = [
  { name: "Jonathan Thompson", date: "Feb 1, 2025", rating: 5, comment: "I love how easy it is to apply for multiple jobs. The site automatically tailors my resume, cover letter, and even a note for the hiring manager. Total game-changer!" },
  { name: "Emily Rodriguez", date: "Feb 2, 2025", rating: 5, comment: "Noticed increased interest from employers within a week! Super user-friendly. I just select the job, and it creates everything I need." },
  { name: "Alex Johnson", date: "Feb 5, 2025", rating: 4, comment: "The AI is really impressive for customizing each application. I'd love more design templates, but the features are top-notch." },
  { name: "Michael Carter", date: "Feb 7, 2025", rating: 5, comment: "This website saved me so much time. I went from manually applying to dozens of jobs to just clicking a few buttons. Highly recommend!" },
  { name: "Sophia Lee", date: "Feb 10, 2025", rating: 4, comment: "The AI-generated cover letters are spot-on! I’d give it 5 stars if there were more customization options for formatting." },
  { name: "David Kim", date: "Feb 12, 2025", rating: 5, comment: "I was skeptical at first, but this tool helped me get noticed by top companies. The AI does a fantastic job tailoring applications." },
  { name: "Jessica Martinez", date: "Feb 15, 2025", rating: 4, comment: "Great tool for job seekers! It would be nice to have a feature that tracks application statuses in real-time." },
  { name: "Chris Evans", date: "Feb 18, 2025", rating: 5, comment: "I've never had an easier job search. The resume and cover letter suggestions make me feel more confident when applying!" },
  { name: "Amanda Reynolds", date: "Feb 20, 2025", rating: 3, comment: "The AI-generated applications are helpful, but I wish there were more industry-specific templates to choose from." },
  { name: "Daniel Parker", date: "Feb 22, 2025", rating: 5, comment: "This service made job applications effortless! I love how the AI highlights my strengths in each cover letter." },
  { name: "Jordan Baker", date: "Feb 23, 2025", rating: 5, comment: "Never thought applying to jobs could be this fast. The AI picks out key skills from my resume and matches them to job descriptions!" },
  { name: "Taylor Simmons", date: "Feb 24, 2025", rating: 4, comment: "Love the automated cover letter feature! Wish there were a bit more customization options, but overall, a fantastic tool!" },
  { name: "Megan Foster", date: "Feb 25, 2025", rating: 5, comment: "I used to spend hours customizing each application. Now, it’s done in minutes! Highly recommend this platform." },
  { name: "Brandon Hayes", date: "Feb 26, 2025", rating: 4, comment: "My applications look so much more professional now. I’d love an option to tweak the hiring manager message templates." },
  { name: "Rachel Adams", date: "Feb 27, 2025", rating: 5, comment: "This site significantly improved the response to my applications! The ATS-optimized resumes are a game-changer." },
  { name: "Steven Carter", date: "Feb 28, 2025", rating: 5, comment: "The AI makes sure my resume is keyword-rich for each job. I noticed a difference in application responses after just a few uses!" },
  { name: "Vanessa Brooks", date: "Feb 29, 2025", rating: 4, comment: "I love the ease of use. If they add a way to track my application statuses, it’ll be perfect!" },
  { name: "Ethan Moore", date: "Feb 30, 2025", rating: 5, comment: "The hiring manager notes feel so personalized! They make a huge difference in getting responses." },
  { name: "Vanessa Lee", date: "Jan 1, 2025", rating: 5, comment: "This website is a must-have for job seekers. It saves time and makes applications much more effective!" },
  { name: "Liam Harris", date: "Jan 2, 2025", rating: 5, comment: "This site is the best investment in my career. I saw significant improvements in my job search in just a few weeks!" },
  { name: "Olivia Knight", date: "Jan 3, 2025", rating: 5, comment: "If you're applying to multiple jobs, this is a must-have! The AI tailors everything perfectly." },
  { name: "Benjamin Lewis", date: "Jan 4, 2025", rating: 5, comment: "Super efficient! I used to dread job applications, but now it's a breeze. I love the automated resume adjustments." },
  { name: "Emma Scott", date: "Jan 5, 2025", rating: 4, comment: "Really helpful tool. I'd love an option to save favorite job applications and track progress." },
  { name: "Lucas Reed", date: "Jan 6, 2025", rating: 5, comment: "The AI-generated cover letters are on point! Saves me hours of work." },
  { name: "Sophia Bennett", date: "Jan 7, 2025", rating: 5, comment: "Love how the site personalizes everything. My job search has never been more effective!" },
  { name: "Noah Mitchell", date: "Jan 8, 2025", rating: 4, comment: "Works well! I'd appreciate an option to edit AI-generated text before finalizing." },
  { name: "Isabella Carter", date: "Jan 9, 2025", rating: 5, comment: "Game-changer! My ATS scores have never been higher, and I'm noticing increased interest from employers." },
  { name: "Jack Turner", date: "Jan 10, 2025", rating: 5, comment: "So easy to use! This site takes the stress out of job applications." },
  { name: "Avery Collins", date: "Jan 11, 2025", rating: 5, comment: "The ATS optimization feature is magic. I finally feel like my resume is getting noticed!" }
];



function SocialProof({ inModal = false }) {
  // Sample reviews list
  

  // Determine if screen is small (or if in modal)
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // For modal or small screens, force one review per page; otherwise, show 4 reviews.
  const reviewsPerPage = inModal || isSmallScreen ? 1 : 4;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + reviewsPerPage >= reviews.length ? 0 : prevIndex + reviewsPerPage
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [reviewsPerPage, reviews.length]);

  // Determine the displayed reviews.
  const displayedReviews =
    reviewsPerPage === 1 ? [reviews[currentIndex]] : reviews.slice(currentIndex, currentIndex + reviewsPerPage);

  // Star rating summary (only used in full view)
  const averageRating = 4.8;
  const totalReviews = 1673;

  // With this
const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

  return (
    <aside className={`social-proof-container ${inModal ? "modal-social-proof" : ""}`}
      data-reader="no"
      data-article="false">
      {/* Show heading and rating summary only on larger screens and when not in modal */}
      {!(inModal || isSmallScreen) && (
        <>
          <h2 className="social-proof-heading">What users are saying</h2>
          <div className="rating-summary">
            <p className="rating-text">Excellent</p>
            <div className="star-rating">
              {Array.from({ length: 5 }, (_, i) => (
                <FaStar key={i} color={i < Math.floor(averageRating) ? "#ffc107" : "#ccc"} />
              ))}
            </div>
            <p className="rating-score">{averageRating} out of 5</p>
            <p className="rating-subtext">
                Feedback inspired by over 1,000 job seekers
            </p>
          </div>
        </>
      )}

      <div
        className="reviews-container"
        style={{ position: reviewsPerPage === 1 ? "relative" : "static", minHeight: "120px" }}
      >
        <AnimatePresence mode="wait">
          {displayedReviews.map((review, idx) => (
            <motion.div
              key={reviewsPerPage === 1 ? `review-${currentIndex}` : `${review.name}-${idx}-${currentIndex}`}
              className="review-card"
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5, ease: "easeInOut" }}
              style={reviewsPerPage === 1 ? { position: "absolute", left: 0, right: 0, margin: "0 auto" } : {}}
            >
              <div className="reviewer-info">
                <h4>{review.name}</h4>
                <p className="review-date">{review.date}</p>
              </div>
              <div className="review-stars">
                {Array.from({ length: review.rating }, (_, i) => (
                  <FaStar key={i} color="#ffc107" />
                ))}
              </div>
              <p className="review-comment">{review.comment}</p>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </aside>
  );
}

export default SocialProof;
