import React from "react";
import { Link } from "react-router-dom";
import "./ProgressBar.css";

/**
 * A compact progress bar with text that properly fits inside step buttons
 * 
 * @param {number} currentStep - Current active step (1-based index)
 * @param {number} totalSteps - Total number of steps in the process
 * @param {string[]} stepLabels - Array of labels for each step
 * @param {string[]} stepPaths - Array of navigation paths for each step
 * @returns {JSX.Element}
 */
const ProgressBar = ({ 
  currentStep, 
  totalSteps, 
  stepLabels = [], 
  stepPaths = [] 
}) => {
  // Ensure valid inputs and handle edge cases
  const safeCurrentStep = Math.max(1, Math.min(currentStep || 1, totalSteps || 1));
  
  // Calculate progress percentage for the bar
  const progressPercentage = totalSteps > 1 
    ? ((safeCurrentStep - 1) / (totalSteps - 1)) * 100 
    : 100;

  // Function to shorten labels when space is constrained
  const getShortenedLabel = (label) => {
    // Create shortened versions of common words
    const shortened = label
      .replace('Upload', 'Upload')
      .replace('Resume', 'Resume')
      .replace('Details', 'Detail')
      .replace('Critique', 'Review')
      .replace('Optimize', 'Final')
      .replace('Finalize', 'Final');
    
    return shortened;
  };

  return (
    <div className="compact-progress-container">
      {/* Step buttons with better text handling */}
      <div className="compact-progress-steps">
        {stepLabels.map((label, index) => {
          const stepNumber = index + 1;
          const isActive = stepNumber === safeCurrentStep;
          const isCompleted = stepNumber < safeCurrentStep;
          const path = stepPaths[index] || "#";
          
          // Use actual links if paths are provided, otherwise use div
          const StepComponent = stepPaths[index] ? Link : 'div';
          const stepProps = stepPaths[index] ? { 
            to: path,
            onClick: (e) => { if (isActive) e.preventDefault(); }
          } : {};
          
          // Get shortened version of label for smaller screens
          const shortLabel = getShortenedLabel(label);
          
          return (
            <StepComponent
              key={index}
              {...stepProps}
              className={`compact-progress-step ${isActive ? "active" : ""} ${isCompleted ? "completed" : ""}`}
            >
              <div className="step-content">
                <span className="step-number">{stepNumber}. </span>
                <span className="step-label">{shortLabel}</span>
              </div>
            </StepComponent>
          );
        })}
      </div>
      
      {/* Progress bar */}
      <div className="compact-progress-track">
        <div
          className="compact-progress-fill"
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;